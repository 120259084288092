import Fund from './FundComponents/Fund';
import translations from '../../locales/en.js';

import Loader from '../Loader/Loader';
import useLoading from '../Loader/useLoading';

function Macro() {
    const isLoaded = useLoading();
    return (
        
      <Loader isLoaded={isLoaded}>
        <Fund
      fundId={'macro'} 
      fundName={'Macro Fund'} 
      fundDescription={translations.fund_macro_description} 
       colorA={'rgb(224, 93, 221)'} 
       colorB={'rgb(74, 13, 66)'}/>
       </Loader>
  );
}

export default Macro;
