import Fund from './FundComponents/Fund.js';
import  translations from '../../locales/en.js';

import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

function Money() {
    const isLoaded = useLoading();
    return (
        
        <Loader isLoaded={isLoaded}>
            <Fund
        fundId={'money'} 
        fundName={'Money Market Fund'} 
        fundDescription={translations.fund_money_description} 
        colorA={'rgb(50, 204, 81)'} 
        colorB={'rgb(26, 143, 112)'}/>
        </Loader>
    );
}

export default Money;
