import { React, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './Navbar.css';
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // State for hamburger menu
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024); // Track if the screen is small
    const sideMenuRef = useRef(null); // Ref for the side menu container
    const navbarRef = useRef(null); // Ref for the navbar container
    const shouldShowNav = !['/login', '/register'].includes(location.pathname);

    const handleLogout = () => {
        //logout(); // Call the logout method from context
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        return !isOpen;
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    // Close the menu when clicking outside of the sidebar
    const handleClickOutside = (event) => {
        if (sideMenuRef.current && !sideMenuRef.current.contains(event.target) && 
            navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsOpen(false); // Close the sidebar when clicking outside
        }
    };

    // Update `isMobile` state and close sidebar on resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Set to true if the screen width is 768px or less
            if (window.innerWidth > 1024) {
                setIsOpen(false); // Close the sidebar when screen width exceeds 768px
                
            }
        };

        window.addEventListener('resize', handleResize); // Listen to resize events
        return () => window.removeEventListener('resize', handleResize); // Cleanup on component unmount
    }, []);

    useEffect(() => {
        // Attach the event listener for clicks outside the sidebar
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside); // Cleanup
    }, []);

    return (
        <div>
            {shouldShowNav && (
                <nav className="navbar" ref={navbarRef}>
                    <div className="navbar-content">
                        {/* Hamburger Icon (mobile only) */}
                        {isMobile && <HamburgerIcon className="hamburger" size={30} isActive={isOpen} onClick={toggleMenu} color="var(--logo-color)" />}
                        
                        {/* Side menu (for mobile screens) */}
                        <div ref={sideMenuRef} className={`side-menu ${isOpen ? 'open' : ''}`}>
                            <ul>
                                <li><Link to="/" onClick={closeMenu} className={`${location.pathname === '/' ? 'active' : ''}`}>Home</Link></li>
                                <li><Link to="/about" onClick={closeMenu} className={`${location.pathname === '/about' ? 'active' : ''}`}>About</Link></li>
                                <li><Link to="/podcast" onClick={closeMenu} className={`${location.pathname === '/podcast' ? 'active' : ''}`}>Podcast</Link></li>
                                <li><Link to="/tools" onClick={closeMenu} className={`${location.pathname === '/tools' ? 'active' : ''}`}>Tools</Link></li>
                                <li><Link to="/funds" onClick={closeMenu} className={`${location.pathname === '/funds' ? 'active' : ''}`}>Funds</Link></li>
                                <li><Link to="/charts" onClick={closeMenu} className={`${location.pathname === '/charts' ? 'active' : ''}`}>Charts</Link></li>
                                <li><Link to="/store" onClick={closeMenu} className={`${location.pathname === '/store' ? 'active' : ''}`}>Store</Link></li>
                            </ul>
                        </div>

                        {/* Logo */}
                        <div className="logo">
                            <Link to="/" className="logo-woodworth">
                                <img 
                                    src="/images/logo.webp" // Your transparent background image
                                    alt="logo"
                                    className="logo"
                                />
                            </Link>
                        </div>

                        {/* Navbar buttons */}
                        <div className="nav-items">
                            <Link
                                to="/"
                                className={`navbar-button ${location.pathname === '/' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Home
                            </Link>
                            <Link
                                to="/about"
                                className={`navbar-button ${location.pathname === '/about' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                About
                            </Link>
                            <Link
                                to="/podcast"
                                className={`navbar-button ${location.pathname === '/podcast' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Podcast
                            </Link>
                            <Link
                                to="/tools"
                                className={`navbar-button ${location.pathname === '/tools' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Tools
                            </Link>
                            <Link
                                to="/funds"
                                className={`navbar-button ${location.pathname === '/funds' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Funds
                            </Link>
                            <Link
                                to="/charts"
                                className={`navbar-button ${location.pathname === '/charts' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Charts
                            </Link>
                            <Link
                                to="/store"
                                className={`navbar-button ${location.pathname === '/store' ? 'active' : ''}`}
                                onClick={closeMenu}
                            >
                                Store
                            </Link>
                        </div>
                    </div>
                </nav>
            )}
        </div>
    );
};

export default Navbar;
