import React from 'react';
import './DynamicLoader.css';

const DynamicLoader = () => {
  return (
    <div className="dynamic-loader">
  <div className="dot dot1"></div>
  <div className="dot dot2"></div>
  <div className="dot dot3"></div>
  <div className="dot dot4"></div>
  <div className="dot dot5"></div>
  <div className="dot dot6"></div>
  <div className="dot dot7"></div>
  <div className="dot dot8"></div>
  <div className="dot dot9"></div>
  <div className="dot dot10"></div>
  <div className="dot dot11"></div>
  <div className="dot dot12"></div>
</div>
  );
};

export default DynamicLoader;