const translations = {
    homepage_podcast_title: "The Exchange Room Podcast",
    homepage_podcast_desc: "Hosted by Theodore Woodworth, with new episodes every month. Tune in for educational discussions on topics like technology, finance, economics, politics, science, and current events, along with personal stories and insights from a diverse list of guests.",
    homepage_charts_title: "Interactive Charts",
    homepage_charts_desc: "Track key economic data and market trends with our interactive Charts page. Receive alerts and build personalized watchlists for deeper insights and easier monitoring.",
    homepage_tools_title: "Financial Tools Suite",
    homepage_tools_desc: "Analyze investment portfolios and plan strategies using our suite of financial tools, including our backtester, stock screener, and portfolio optimizer.",
    homepage_store_title: "Store",
    homepage_store_desc: "Browse a carefully curated selection of educational tools, must-read books, and high-quality merchandise. Every item has been personally used or read by me and has provided significant value in both my life and career.",
    homepage_indices_title: "Custom Indices",
    homepage_indices_desc: "Designed to highlight hidden patterns and provide a clearer understanding of current and historical market behaviors.",
    homepage_funds_title: "Investment Funds",
    homepage_funds_desc: "Follow the performance of the company's investment funds, which currently include the Macro Fund, Value Fund, and Alternatives Fund. (Funds are not currently open to new investors.)",
    about_p1: "\"I started rosswoodworth.com with a mission to transform the world, starting in the financial industry. With a background in software engineering and finance, I set out to create a company that provides sophisticated financial tools and insights at a fair price, enabling smarter financial decisions by individuals and institutions alike.",
    about_p2: "I believe that by increasing access to knowledge and resources, we inspire curiosity and innovation. With the support of my family, colleagues, and an ever-growing community, we’re shaping a future driven by knowledge, curiosity, and truth.\"",
    about_signature1: "Theodore Ross Woodworth",
    about_signature2: "Owner & Founder",
    about_signature3: "Woodworth Enterprises",
    charts_title: "Charts Coming Soon",
    charts_caption: "Track key economic data and market trends.",
    funds_title: "Investment Funds",
    funds_caption: "All listed funds are private and not available for public investment. ",
    funds_table_name: "Name",
    funds_table_asset_class: "Asset Class",
    funds_table_inception: "Inception Date",
    funds_table_volatility: "Volatility",

    fund_macro_description: "The objective of this fund is to deliver consistent growth, irrespective of prevailing macroeconomic conditions. It is composed of a diverse range of assets, carefully selected through a highly systematic, data-driven approach.",
    fund_equity_description: "The objective of this fund is to deliver consistent growth by investing in the equity market.",
    fund_alternatives_description: "The objective of this fund is to generate consistent, stable growth while remaining uncorrelated to traditional markets by utilizing alternative asset classes and strategies.",
    fund_money_description: "The objective of this fund is to maintain minimal risk and high liquidity, while also providing stable growth.",
    fund_income_description: "The objective of this fund is to deliver consistent, stable growth via fixed income assets.",
    fund_arbitrage_description: "The objective of this fund is to deliver consistent, stable growth through a variety of strategies that take advantage of market dynamics."


};

export default translations;

