import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, LogarithmicScale, Filler } from 'chart.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Graph.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  Filler);

  
  ChartJS.register(
    {
    id: 'plugin-id',
    afterDraw: function (chart, easing) { // create vertical line when hovering over graph
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY); 
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#FFF';
        ctx.stroke();
        ctx.restore();
      }
    }
  })


const Graph = ({ returnsData, colorA, colorB }) => {
  const [viewType, setViewType] = useState('percentage');
  const [zoomRange, setZoomRange] = useState('All');
  const [sliderRange, setSliderRange] = useState([0, returnsData.length - 1]);
  const [activeZoom, setActiveZoom] = useState(null);
  const chartRef = useRef(null);
  const timeRanges = ['1M', '3M', '6M', 'YTD', '1Y', '3Y', '5Y', 'All'];
  const latestDate = new Date(returnsData[returnsData.length - 1].date);


  function colorToRgba(color, alpha = 1) {
    let r, g, b;
  
    // Check if the color is in hex format (3-digit or 6-digit)
    if (/^#[0-9A-F]{3}$|^#[0-9A-F]{6}$/i.test(color)) {
      // Handle 3-digit hex (e.g., #FFF)
      if (color.length === 4) {
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      }
      else {
        r = parseInt(color.substring(1, 3), 16);
        g = parseInt(color.substring(3, 5), 16);
        b = parseInt(color.substring(5, 7), 16);
      }
    } 

    // Check if the color is in rgb format
    else if (/^rgb\(\d+\s*,\s*\d+\s*,\s*\d+\)$/i.test(color)) {
      // Extract RGB values from the rgb() string
      const rgbValues = color.match(/\d+/g);
      r = parseInt(rgbValues[0], 10);
      g = parseInt(rgbValues[1], 10);
      b = parseInt(rgbValues[2], 10);
    } 
    // If the color format is not recognized, return an error or handle default
    else {
      throw new Error('Unsupported color format');
    }
  
    // Return the color in rgba format
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    
    let r, g, b;
    
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }
  
    return { r, g, b };
  }
  
  // Function to convert rgb to RGB object
  function rgbToRgbObject(rgb) {
    const rgbValues = rgb.match(/\d+/g);
    return { r: parseInt(rgbValues[0]), g: parseInt(rgbValues[1]), b: parseInt(rgbValues[2]) };
  }
  
  // Function to average two colors
  function averageColors(color1, color2) {
    let color1Rgb, color2Rgb;
  
    // Check if color1 is in hex or rgb format
    if (color1.startsWith('#')) {
      color1Rgb = hexToRgb(color1); // Convert hex to RGB
    } else if (color1.startsWith('rgb')) {
      color1Rgb = rgbToRgbObject(color1); // Convert rgb to RGB object
    }
  
    // Check if color2 is in hex or rgb format
    if (color2.startsWith('#')) {
      color2Rgb = hexToRgb(color2); // Convert hex to RGB
    } else if (color2.startsWith('rgb')) {
      color2Rgb = rgbToRgbObject(color2); // Convert rgb to RGB object
    }
  
    // Calculate the average of the RGB components
    const avgR = Math.round((color1Rgb.r + color2Rgb.r) / 2);
    const avgG = Math.round((color1Rgb.g + color2Rgb.g) / 2);
    const avgB = Math.round((color1Rgb.b + color2Rgb.b) / 2);
  
    // Return the average color in RGB format
    return `rgb(${avgR}, ${avgG}, ${avgB})`; // Or use `rgba()` if alpha is needed
  }

  const getGraphData = () => {
    const filteredData = returnsData.slice(sliderRange[0], sliderRange[1] + 1);
    const labels = filteredData.map(item => item.date);

    let data;
    if (viewType === 'percentage') {
      const firstReturn = filteredData[0].cumulative_return;
      data = filteredData.map((item, index) => 100 * ((1 + item.cumulative_return) / (1 + firstReturn) - 1));
      data = data.map(value => Math.round(value * 100) / 100);
    } else {
      const firstReturn = filteredData[0].cumulative_return;
      data = filteredData.map(item => 10000 * (1 + item.cumulative_return) / (1 + firstReturn));
      data = data.map(value => Math.round(value * 100) / 100);
    }

    return {
      labels,
      datasets: [
        {
          label: viewType === 'percentage' ? 'Cumulative Return (%)' : 'Portfolio Value ($)',
          data,
          borderColor: colorA,
          pointRadius: 0,
          tension: 0.1,
          backgroundColor: colorToRgba(averageColors(colorA, colorB), 0.45),
          fill: true,
        },
      ],
    };
  };

  const updateGraphForZoom = () => {
    if (zoomRange === 'All') {
      setSliderRange([0, returnsData.length - 1]);
    } else if (zoomRange === 'YTD') {
      const startDate = new Date(latestDate.getFullYear(), 0, 1);
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    } else if (zoomRange === '1Y') {
      const startDate = new Date(latestDate.getFullYear() - 1, latestDate.getMonth(), latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    } else if (zoomRange === '3Y') {
      const startDate = new Date(latestDate.getFullYear() - 3, latestDate.getMonth(), latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    } else if (zoomRange === '5Y') {
      const startDate = new Date(latestDate.getFullYear() - 5, latestDate.getMonth(), latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    }  else if (zoomRange === '1M') {
      const startDate = new Date(latestDate.getFullYear(), latestDate.getMonth() - 1, latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    } else if (zoomRange === '3M') {
      const startDate = new Date(latestDate.getFullYear(), latestDate.getMonth() - 3, latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    } else if (zoomRange === '6M') {
      const startDate = new Date(latestDate.getFullYear(), latestDate.getMonth() - 6, latestDate.getDate());
      const filteredData = returnsData.filter(item => new Date(item.date) >= startDate);
      setSliderRange([returnsData.indexOf(filteredData[0]), returnsData.indexOf(filteredData[filteredData.length - 1])]);
    }
  };

  useEffect(() => {
    updateGraphForZoom();
  }, [zoomRange]);

  const chartData = getGraphData();

  const calculateYAxisLimits = (data) => {
    const min = Math.min(...data);
    const max = Math.max(...data);
    const buffer = viewType === 'percentage' ? 0.1 * (max - min) : 1000;
    let roundedMin, roundedMax;
    
    if (viewType === 'percentage') {
      roundedMin = Math.trunc(min) - 1;
      roundedMax = Math.trunc(max) + 1;
    } else if (viewType === 'dollar') {
      roundedMin = 0;
      roundedMax = Math.round((max + buffer) / 100) * 100;
    }
  
    return {
      min: roundedMin, 
      max: roundedMax
    };
  };
  
  const yAxisLimits = calculateYAxisLimits(chartData.datasets[0].data);

  const handleZoomButtonClick = (range) => {
    setZoomRange(range);
    setActiveZoom(range);
  };

  const handleSliderChange = (newRange) => {
    handleZoomButtonClick(null);
    setSliderRange(newRange);
  };

  const renderZoomButtons = () => {
    return timeRanges.map((range) => {
      return (
        <button
          key={range}
          className={`zoom-button ${activeZoom === range ? 'active' : ''}`}
          onClick={() => handleZoomButtonClick(range)}
        >
          {range}
        </button>
      );
    });
  };

  // You can target the slider's specific CSS classes by using custom styles
  const sliderStyles = {
    rail: {
      backgroundColor: '#555',
      height: '6px',  // You can adjust the height here
    },
    track: {
      backgroundColor: colorA,
      height: '6px',  // Adjust height for track
    },
    handle: {
      backgroundColor: colorA,
      borderColor: colorA,
      opacity: 1,
      'marginTop': '-6.95px',
      width: '20px',
      height: '20px',
      borderRadius: '50%', // Optional, for circular handles
    },
    handleHover: {
      backgroundColor: colorA,
      borderColor: colorA,
    },
  };

  return (
    <section className="interactive-graph">
      <div className="graph-controls">
        <label className='check'>
          <input
            type="checkbox"
            checked={viewType === 'dollar'}
            onChange={() => setViewType(viewType === 'percentage' ? 'dollar' : 'percentage')}
          />
          Growth of 10K
        </label>

        <div className="zoom-controls">
          {renderZoomButtons()}
        </div>
      </div>

      <Line
        ref={chartRef}
        data={chartData}
        options={{
          responsive: true,
          animation: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                color: '#EEE',
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: zoomRange === '5Y' ? 20 : 50,
              },
            },
            y: {
              ticks: {
                color: '#EEE',
                callback: (value) => viewType === 'percentage' ? `${value}%` : `$${value.toFixed(0)}`,
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.8)',
                lineWidth: 1,
              },
              min: yAxisLimits.min, 
              max: yAxisLimits.max,
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />

      <div className="slider-container">
        <Slider
          min={0}
          max={returnsData.length - 1}
          value={sliderRange}
          onChange={handleSliderChange}
          range
          styles={sliderStyles}
        />
      </div>
    </section>
  );
};

export default Graph;
