import React from 'react';
import './About.css';
import translations from '../../locales/en.js'
import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

const About = () => {
  const isLoaded = useLoading();
  return (
    <Loader isLoaded={isLoaded}>
      <div className="about-container">
        <div className="background">

        </div>
        <div className="text-box">
          <div className="about-text">
          <p>{translations.about_p1}</p>
          <p>{translations.about_p2}</p>
          <p className="cursive-text">~ Theodore Woodworth</p>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default About;
