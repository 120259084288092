import React from 'react';
import './Loader.css';

const Loader = ({ isLoaded, children }) => {
  return (
    <div>
      <div className={`load-container ${isLoaded ? 'loaded' : ''}`}>
        {isLoaded ? (
          <>{children}</>
        ) : (
          <div className="favicon-container">
            <img
              src="/web-app-manifest-512x512.png"
              alt="Loading..."
              className={`favicon ${isLoaded ? 'hide' : ''}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
