import Fund from './FundComponents/Fund.js';
import  translations from '../../locales/en.js';

import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

function Arbitrage() {
    const isLoaded = useLoading();
    return (
        
        <Loader isLoaded={isLoaded}>
            <Fund
        fundId={'arbitrage'} 
        fundName={'Arbitrage Fund'} 
        fundDescription={translations.fund_arbitrage_description} 
        colorA={'rgb(93, 222, 224)'} 
        colorB={'rgb(13, 74, 33)'}/>
        </Loader>
    );
}

export default Arbitrage;
