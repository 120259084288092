import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useLoading = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkIfLoaded = () => {
      const images = Array.from(document.images);
      let loadedImages = 0;

      const onImageLoad = () => {
        loadedImages += 1;
        if (loadedImages === images.length && document.fonts.ready) {
          setIsLoaded(true);
        }
      };

      if (images.length === 0) {
        document.fonts.ready.then(() => {
          setIsLoaded(true);
        });
        return;
      }

      images.forEach((image) => {
        if (image.complete) {
          onImageLoad();
        } else {
          image.addEventListener('load', onImageLoad);
        }
      });

      document.fonts.ready.then(() => {
        if (loadedImages === images.length) {
          setIsLoaded(true);
        }
      });

      return () => {
        images.forEach((image) => {
          image.removeEventListener('load', onImageLoad);
        });
      };
    };

    checkIfLoaded();
  }, [location]); // Trigger loader reset when route changes

  return isLoaded;
};

export default useLoading;
