import React, { useState } from 'react';
import './CompoundInterest.css';
import DatePicker from 'react-datepicker';
import { Chart as ChartJS, LineController, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../Loader/Loader';
import useLoading from '../../../Loader/useLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DynamicLoader from '../../../DynamicLoader/DynamicLoader';

// Register ChartJS components
ChartJS.defaults.plugins.tooltip.enabled = false;
ChartJS.register(LineController, PointElement, LineElement, Tooltip, Legend);

const CompoundInterest = () => {
  const isLoaded = useLoading();

  // Form state
  const [initialDeposit, setInitialDeposit] = useState('10000');
  const [yearsOfGrowth, setYearsOfGrowth] = useState('10');
  const [compoundFrequency, setCompoundFrequency] = useState('month');
  const [annualizedRate, setAnnualizedRate] = useState('5');
  const [contributionFrequency, setContributionFrequency] = useState('1');
  const [contributionFrequencyUnit, setContributionFrequencyUnit] = useState('months');
  const [contributionAmount, setContributionAmount] = useState('100');
  const [startDate, setStartDate] = useState(new Date());
  const [outputFrequency, setOutputFrequency] = useState('month');

  // Results state
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disclosureOpen, setDisclosureOpen] = useState(false);
  const [overviewOpen, setOverviewOpen] = useState(false);

  // Handle form submission
  const handleSubmit = async () => {
    const missingFields = [];
    if (!initialDeposit) missingFields.push('Initial Deposit');
    if (!yearsOfGrowth) missingFields.push('Years of Growth');
    if (!annualizedRate) missingFields.push('Annual Interest Rate');
    if (!contributionFrequency) missingFields.push('Contribution Frequency');
    if (!contributionAmount) missingFields.push('Contribution Amount');
    if (!startDate) missingFields.push('Start Date');

    if (missingFields.length > 0) {
      alert(`Please fill in the following required fields: ${missingFields.join(', ')}.`);
      return;
    }

    const requestBody = {
      initial_deposit: parseFloat(initialDeposit),
      years_of_growth: parseFloat(yearsOfGrowth),
      compound_frequency: compoundFrequency,
      annualized_rate_of_return: parseFloat(annualizedRate) / 100,
      contribution_frequency: parseInt(contributionFrequency),
      contribution_frequency_unit: contributionFrequencyUnit,
      contribution_amount: parseFloat(contributionAmount),
      start_date: startDate.toISOString().split('T')[0],
      output_frequency: outputFrequency,
    };

    setLoading(true);
    try {
      const response = await fetch('https://api.rosswoodworth.com/tools/compound-interest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.error) {
        alert(data.error);
      } else {
        setResults(data);
      }
    } catch (error) {
      alert('Error fetching results: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Format date based on output frequency
  const formatDate = (dateStr, frequency) => {
    const date = new Date(dateStr);
    if (frequency === 'year') {
      return date.getFullYear().toString(); // e.g., "2025"
    } else if (frequency === 'month') {
      return date.toLocaleString('default', { year: 'numeric', month: 'short' }); // e.g., "Mar 2025"
    }
    return dateStr; // Default to full date for "day"
  };

  // Chart data for growth and principal
  const chartData = results
    ? {
        labels: results.timeline.map((point) => formatDate(point.date, outputFrequency)),
        datasets: [
          {
            label: 'Investment Value',
            data: results.timeline.map((point) => point.value),
            borderColor: 'rgba(0, 255, 0, 1)', // Green line
            backgroundColor: 'rgba(0, 255, 0, 0)', // No fill
            fill: false,
            tension: 0.1,
          },
          {
            label: 'Principal Contributed',
            data: results.timeline.map((point) => point.principal),
            borderColor: 'rgba(50, 150, 255, 1)', // Blue line
            backgroundColor: 'rgba(50, 150, 255, 0.2)', // Light blue fill
            fill: true,
            tension: 0.1,
          },
        ],
      }
    : null;

  return (
    <Loader isLoaded={isLoaded}>
      <div className="compound-interest-container">
        <div className="background"></div>

        {/* Overview */}
        <div className="text-box block">
          <div className="title-text">
            <h1 className="compound-interest-title">Compound Interest Calculator</h1>
            <h3
              className="compound-interest-subsubtitle"
              onClick={() => setOverviewOpen(!overviewOpen)}
            >
              Overview <FontAwesomeIcon icon={overviewOpen ? faChevronDown : faChevronRight} />
            </h3>
            {overviewOpen && (
              <div className="compound-interest-description">
                <p>
                  Plan your financial future with our Compound Interest Calculator, a powerful tool designed to illustrate the growth of your investments over time. By factoring in regular contributions and compound interest, this calculator provides a clear picture of how your savings can grow, helping you achieve your wealth-building goals.
                </p>
                <p>
                  Perfect for personal finance, retirement planning, or understanding the power of compounding, this tool allows you to customize inputs like initial deposit, growth period, interest rate, and contribution frequency. Visualize your investment growth and principal contributions with an interactive graph tailored to your timeline.
                </p>
                <p>
                  Whether you’re saving for a major purchase or building long-term wealth, our Compound Interest Calculator offers data-driven insights to optimize your strategy. Explore how small, consistent investments can compound into significant returns over time.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Form */}
        <div className="text-box block settings">
          <h2 className="compound-interest-subtitle">Compound Interest Settings</h2>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Initial Deposit ($)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">The starting amount of money you invest with.</div>
              </span>
            </div>
            <input
              type="number"
              value={initialDeposit}
              onChange={(e) => setInitialDeposit(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Years of Growth</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">How long your investment will grow.</div>
              </span>
            </div>
            <input
              type="number"
              step="0.1"
              value={yearsOfGrowth}
              onChange={(e) => setYearsOfGrowth(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Start Date</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">The date when your investment begins.</div>
              </span>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              className="input-field date-picker"
              showYearDropdown
              showMonthDropdown
              yearDropdownItemNumber={10}
              scrollableYearDropdown
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Compound Frequency</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">How frequently interest is received</div>
              </span>
            </div>
            <select
              value={compoundFrequency}
              onChange={(e) => setCompoundFrequency(e.target.value)}
              className="input-field"
            >
              <option value="day">Daily</option>
              <option value="month">Monthly</option>
              <option value="year">Annually</option>
            </select>
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Annual Interest Rate (%)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">The expected percent interest to be received over the course of one year.</div>
              </span>
            </div>
            <input
              type="number"
              step="0.1"
              value={annualizedRate}
              onChange={(e) => setAnnualizedRate(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="config-row contribution-frequency-row">
            <div className="label-wrapper">
              <label>Contribution Frequency</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">How often you add money into your investment.</div>
              </span>
            </div>
            <div className="input-group">
              <input
                type="number"
                value={contributionFrequency}
                onChange={(e) => setContributionFrequency(e.target.value)}
                className="input-field"
                style={{ width: '70px', marginRight: '5px' }}
              />
              <select
                value={contributionFrequencyUnit}
                onChange={(e) => setContributionFrequencyUnit(e.target.value)}
                className="input-field"
                style={{ width: '80px' }}
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Contribution Amount ($)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">The amount of money added during each contribution.</div>
              </span>
            </div>
            <input
              type="number"
              value={contributionAmount}
              onChange={(e) => setContributionAmount(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Output Frequency</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">How often to show data points on the graph.</div>
              </span>
            </div>
            <select
              value={outputFrequency}
              onChange={(e) => setOutputFrequency(e.target.value)}
              className="input-field"
            >
              <option value="day">Daily</option>
              <option value="month">Monthly</option>
              <option value="year">Annually</option>
            </select>
          </div>
          <button onClick={handleSubmit} className="calculate-btn" disabled={loading}>
            {loading ? 'Calculating...' : 'Calculate'}
          </button>
        </div>

        {/* Loader */}
        {loading && (
          <div className="text-box block">
            <DynamicLoader />
          </div>
        )}

        {/* Results */}
        {results && (
          <div className="text-box block">
            <h2 className="compound-interest-subtitle">Investment Growth</h2>
            <h3 className="compound-interest-subsubtitle">
              Final Balance: ${results.final_value.toLocaleString()}
            </h3>
            <div className="chart-container">
              <Line
                data={chartData}
                options={{
                  scales: {
                    x: {
                      title: { display: true, text: 'Date', color: 'white' },
                      grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                      ticks: { color: 'white' },
                    },
                    y: {
                      title: { display: true, text: 'Value ($)', color: 'white' },
                      grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                      ticks: {
                        color: 'white',
                        callback: (value) => `$${value.toLocaleString()}`,
                      },
                      min: 0, // Force y-axis to start at 0
                    },
                  },
                  plugins: {
                    legend: { display: true, labels: { color: 'white' } },
                    tooltip: {
                      enabled: true,
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      titleColor: 'white',
                      bodyColor: 'white',
                      borderColor: 'white',
                      borderWidth: 1,
                      mode: 'index',
                      intersect: false,
                      callbacks: {
                        label: (context) => {
                          const datasetLabel = context.dataset.label;
                          const value = context.parsed.y;
                          return `${datasetLabel}: $${value.toLocaleString()}`;
                        },
                      },
                    },
                  },
                  interaction: { mode: 'index', intersect: false },
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>
        )}

        {/* Disclaimer */}
        <div className="text-box block disclosure">
          <h3
            className="compound-interest-subsubtitle"
            onClick={() => setDisclosureOpen(!disclosureOpen)}
          >
            Disclaimer <FontAwesomeIcon icon={disclosureOpen ? faChevronDown : faChevronRight} />
          </h3>
          {disclosureOpen && (
            <p>
              The projections generated by the Compound Interest Calculator are hypothetical and for illustrative purposes only. They do not reflect actual investment results and are not guarantees of future performance. Results may vary with each use and over time. This tool is provided for informational purposes only and does not constitute financial advice or an offer to buy or sell any securities. Investing involves risk, including the possible loss of principal. Past performance is not indicative of future results. The calculator assumes continuous compounding and regular contributions as specified, but does not account for taxes, fees, or inflation unless explicitly stated. All inputs are user-defined, and the accuracy of results depends on the accuracy of those inputs. Use this tool as part of a broader financial planning strategy and consult a financial advisor for personalized advice.
            </p>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default CompoundInterest;