import Fund from './FundComponents/Fund.js';
import  translations from '../../locales/en.js';

import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

function Equity() {
    const isLoaded = useLoading();
    return (
        
        <Loader isLoaded={isLoaded}>
            <Fund
        fundId={'equity'} 
        fundName={'Equity Fund'} 
        fundDescription={translations.fund_equity_description} 
        colorA={'rgb(182, 224, 93)'} 
        colorB={'rgb(50, 74, 13)'}/>
        </Loader>
    );
}

export default Equity;
