// src/components/Podcast/Podcast.js
import React, { useState, useEffect, useRef } from 'react';
import './Podcast.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube, faApple } from '@fortawesome/free-brands-svg-icons';
import useLoading from '../Loader/useLoading';
import Loader from '../Loader/Loader';
import { Link, useLocation } from 'react-router-dom'; // Use useLocation, not useNavigation
import Swal from 'sweetalert2';

function Podcast({ episodes, podcastDescription }) {
  const isLoaded = useLoading();
  const [filteredEpisodes, setFilteredEpisodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTopics, setFilterTopics] = useState([]);
  const [sortOption, setSortOption] = useState('date-desc');
  const [visibleEpisodes, setVisibleEpisodes] = useState(5);
  const [email, setEmail] = useState('');
  const [showTopicDropdown, setShowTopicDropdown] = useState(false);
  const observer = useRef();
  const location = useLocation(); // Correct hook for accessing URL params

  useEffect(() => {
    setFilteredEpisodes(episodes);
  }, [episodes]);

  useEffect(() => {
    let result = [...episodes];
    if (searchTerm) {
      result = result.filter(ep =>
        ep.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ep.guest.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ep.topics.some(topic => topic.toLowerCase().includes(searchTerm.toLowerCase())) ||
        ep.overview.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (filterTopics.length > 0) {
      result = result.filter(ep =>
        filterTopics.every(topic => ep.topics.some(t => t.toLowerCase() === topic.toLowerCase()))
      );
    }
    result.sort((a, b) => {
      if (sortOption === 'date-desc') return b.pubDate - a.pubDate;
      if (sortOption === 'date-asc') return a.pubDate - b.pubDate;
      if (sortOption === 'length-desc') return parseDuration(b.length) - parseDuration(a.length);
      if (sortOption === 'length-asc') return parseDuration(a.length) - parseDuration(b.length);
      return 0;
    });
    setFilteredEpisodes(result);
  }, [searchTerm, filterTopics, sortOption, episodes]);

  const parseDuration = (duration) => {
    if (!duration || duration === 'N/A') return 0;
    const parts = duration.split(':').map(Number);
    return parts[0] * 3600 + parts[1] * 60 + (parts[2] || 0);
  };

  const formatDuration = (duration) => {
    const totalSeconds = parseDuration(duration);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours} hr, ${minutes} min`;
    } else if (totalSeconds >= 300) {
      return `${minutes} min`;
    } else if (totalSeconds >= 60) {
      return `${minutes} min, ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  const lastEpisodeRef = useRef();
  useEffect(() => {
    if (!lastEpisodeRef.current) return;
    const callback = (entries) => {
      if (entries[0].isIntersecting && visibleEpisodes < filteredEpisodes.length) {
        setVisibleEpisodes(prev => prev + 5);
      }
    };
    observer.current = new IntersectionObserver(callback, { threshold: 0.1 });
    observer.current.observe(lastEpisodeRef.current);
    return () => observer.current?.disconnect();
  }, [filteredEpisodes, visibleEpisodes]);

  useEffect(() => {
    const params = new URLSearchParams(location.search); 
    const confirmStatus = params.get('confirm');

    if (confirmStatus === 'success') {
      Swal.fire({
        icon: 'success',
        title: 'Follow Confirmed',
        text: 'Thank you for following The Exchange Room Podcast!',
        confirmButtonColor: '#3085d6',
      });
    } else if (confirmStatus === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Confirmation Failed',
        text: 'The confirmation link is invalid or has expired. Please try following again.',
        confirmButtonColor: '#d33',
      });
    }
  }, [location.search]); // Dependency on location.search

  const uniqueTopics = [...new Set(episodes.flatMap(ep => ep.topics))];

  const handleTopicChange = (topic) => {
    setFilterTopics(prev =>
      prev.includes(topic)
        ? prev.filter(t => t !== topic)
        : [...prev, topic]
    );
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.rosswoodworth.com/mail/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Follow Request Sent!',
          text: result.message.replace('subscribe', 'follow'),
          confirmButtonColor: '#3085d6',
        }).then(() => {
          setEmail('');
        });
      } else {
        if (result.error === 'You are already followed and confirmed.') {
          Swal.fire({
            icon: 'warning',
            title: 'Already following',
            text: 'You are already following the podcast!',
            confirmButtonColor: '#3085d6'
          });
        } else {
          
        Swal.fire({
          icon: 'error',
          title: 'Follow Failed',
          text: result.message ? result.message.replace('subscribe', 'follow') : 'An unknown error occurred. Please try again.',
          confirmButtonColor: '#d33',
        });
        }
      }
    } catch (error) {
      console.error('Error following:', error);
      Swal.fire({
        icon: 'error',
        title: 'Network Error',
        text: 'Unable to connect to the server. Please check your internet connection and try again.',
        confirmButtonColor: '#d33',
      });
    }
  };

  return (
    <Loader isLoaded={isLoaded}>
      <div className="podcast-container">
        <div className="background"></div>
        <div className="text-box block description">
          <p className="podcast-overview">{podcastDescription}</p>
        </div>
        <div className="header">
          <h1 className="podcast-title content-text">The Exchange Room</h1>
          <div className="follow-section">
            <div className="social-buttons">
              <a href="https://open.spotify.com/show/1CxNWKC73V9RO4lxvRGyr0?si=NM0MlSjnQYyFzBcRMRtN7A" target="_blank" rel="noopener noreferrer" className="social-button spotify">
                <FontAwesomeIcon icon={faSpotify} size="2x" />
              </a>
              <a href="https://www.youtube.com/@exchange-room" target="_blank" rel="noopener noreferrer" className="social-button youtube">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
              <a href="https://podcasts.apple.com/us/podcast/the-exchange-room/id1793810056" target="_blank" rel="noopener noreferrer" className="social-button apple">
                <FontAwesomeIcon icon={faApple} size="2x" />
              </a>
            </div>
            <span className="separator content-text">- or -</span>
            <form onSubmit={handleEmailSubmit} className="email-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="email-input"
              />
              <button type="submit" className="follow-btn">Follow</button>
            </form>
          </div>
        </div>
        <div className="search-filter-container">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-options">
            <div className="topic-filter">
              <button
                type="button"
                className="filter-btn"
                onClick={() => setShowTopicDropdown(!showTopicDropdown)}
              >
                Topics {filterTopics.length > 0 ? `(${filterTopics.length})` : ''}
              </button>
              {showTopicDropdown && (
                <div className="topic-dropdown">
                  {uniqueTopics.map(topic => (
                    <label key={topic} className="topic-checkbox">
                      <input
                        type="checkbox"
                        checked={filterTopics.includes(topic)}
                        onChange={() => handleTopicChange(topic)}
                      />
                      {topic.trim()}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <select
              title="sort"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="filter-select"
            >
              <option value="date-desc">Newest First</option>
              <option value="date-asc">Oldest First</option>
              <option value="length-desc">Longest First</option>
              <option value="length-asc">Shortest First</option>
            </select>
          </div>
        </div>
        <div className="episode-list">
          {filteredEpisodes.slice(0, visibleEpisodes).map((episode, index) => (
            <Link
              to={`/podcast/${episode.slug}`}
              key={episode.link}
              className="episode-box"
              ref={index === visibleEpisodes - 1 ? lastEpisodeRef : null}
            >
              <div className="thumbnail-container">
                <img src={episode.thumbnail} alt={episode.title} className="episode-thumbnail" />
                <span className="length">{formatDuration(episode.length)}</span>
              </div>
              <div className="episode-details">
                <h3>
                  Episode {episode.episodeNumber}
                  {episode.guest !== 'Unknown' && ` - ${episode.guest}`}
                </h3>
                <p><strong>Release Date:</strong> {episode.pubDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                <p><strong>Topics:</strong> {episode.topics.length > 0 ? episode.topics.join(', ') : 'N/A'}</p>
                <p><strong>Overview:</strong> {episode.overview.length > 200 ? episode.overview.substring(0, 200) + '...' : episode.overview}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Loader>
  );
}

export default Podcast;