import React from 'react';
import './Charts.css';
import translations from '../../locales/en.js'
import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

const Charts = () => {
  const isLoaded = useLoading();
  return (
    <Loader isLoaded={isLoaded}>
      <div className="charts-container">
        <div className="background">

        </div>
        <div className="text-box">
          <div className="title-text">
            <span><span className="cursive-text">Coming soon:</span> Track key economic data and market trends with our interactive Charts page. Receive alerts and build personalized watchlists for deeper insights and easier monitoring.</span>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default Charts;
