// src/App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { Routes, Route } from 'react-router-dom';
import Homepage from './components/Home/Homepage';
import About from './components/About/About';
import Podcast from './components/Podcast/Podcast';
import Charts from './components/Charts/Charts';
import Store from './components/Store/Store';
import Tools from './components/Tools/Tools';
import Funds from './components/Funds/Funds';
import Macro from './components/Funds/Macro';
import Equity from './components/Funds/Equity';
import Alternatives from './components/Funds/Alternatives';
import Arbitrage from './components/Funds/Arbitrage';
import Income from './components/Funds/Income';
import Money from './components/Funds/Money';
import EfficientFrontier from './components/Tools/portfolio/efficient-frontier/EfficientFrontier';
import CompoundInterest from './components/Tools/investments/compound-interest/CompoundInterest';
import EpisodePage from './components/Podcast/EpisodePage';
import { parseStringPromise } from 'xml2js';

function App() {
  const [episodes, setEpisodes] = useState([]);
  const [podcastDescription, setPodcastDescription] = useState('');

useEffect(() => {
  const fetchRSS = async () => {
    try {
      const response = await fetch('https://podcast.rosswoodworth.com/rss-feed.xml');
      const xmlText = await response.text();
      const data = await parseStringPromise(xmlText);
      const items = data.rss.channel[0].item.map(item => ({
        title: item.title[0],
        episodeNumber: item['itunes:episode'] ? item['itunes:episode'][0] : 'N/A',
        guest: item['itunes:author'] ? item['itunes:author'][0] : 'Unknown',
        topics: item['itunes:keywords'] ? item['itunes:keywords'][0].split(',') : [],
        overview: item.description[0],
        length: item['itunes:duration'] ? item['itunes:duration'][0] : 'N/A',
        thumbnail: item['itunes:image'] ? item['itunes:image'][0].$.href : '/images/default-thumbnail.jpg',
        pubDate: new Date(item.pubDate[0]),
        link: item.link[0],
        slug: item.link[0].split('/').pop() || item.title[0].toLowerCase().replace(/\s+/g, '-'),
        enclosure: item.enclosure ? item.enclosure[0].$.url : null,
      }));
      setEpisodes(items);
      const description = data.rss.channel[0].description ? data.rss.channel[0].description[0] : 'No description available';
      setPodcastDescription(description);
    } catch (error) {
      console.error('Error fetching RSS:', error);
      setPodcastDescription('Failed to load podcast description.');
    }
  };
  fetchRSS();
}, []);

  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/store" element={<Store />} />

          {/* Podcast */}
          <Route path="/podcast/:slug" element={<EpisodePage episodes={episodes} />} />
          <Route path="/podcast" element={<Podcast episodes={episodes} podcastDescription={podcastDescription} />} />

          {/* Tools */}
          <Route path="/tools/efficient-frontier" element={<EfficientFrontier />} />
          <Route path="/tools/compound-interest" element={<CompoundInterest />} />
          <Route path="/tools" element={<Tools />} />

          {/* Funds */}
          <Route path="/funds/macro" element={<Macro />} />
          <Route path="/funds/equity" element={<Equity />} />
          <Route path="/funds/alternatives" element={<Alternatives />} />
          <Route path="/funds/income" element={<Income />} />
          <Route path="/funds/arbitrage" element={<Arbitrage />} />
          <Route path="/funds/money" element={<Money />} />
          <Route path="/funds" element={<Funds />} />

          {/* Catch all */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;