import './HamburgerIcon.css';

const HamburgerIcon = ({ size = 30, color = 'whitesmoke', isActive, onClick }) => {
    const barHeight = size * 0.15;  // Proportional height for each bar
    const barSpacing = size * 0.1;  // Spacing between bars

    return (
        <div 
            className={`hamburger-menu-icon ${isActive ? 'active' : ''}`} 
            style={{
                width: `${size}px`, 
                height: `${size * 0.83}px`,  // Maintaining aspect ratio
            }}
            onClick={onClick}
        >
            <span 
                className="bar top" 
                style={{
                    backgroundColor: color, 
                    height: `${barHeight}px`, // Dynamic bar height based on the size
                    marginBottom: `${barSpacing}px` // Space between top and middle bars
                }}
            ></span>
            <span 
                className="bar middle" 
                style={{
                    backgroundColor: color, 
                    height: `${barHeight}px`,
                    marginBottom: `${barSpacing}px` // Space between middle and bottom bars
                }}
            ></span>
            <span 
                className="bar bottom" 
                style={{
                    backgroundColor: color, 
                    height: `${barHeight}px`
                }}
            ></span>
        </div>
    );
}

export default HamburgerIcon;
