import React, { useState, useEffect } from 'react';
import './Homepage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
import useLoading from '../Loader/useLoading';

const Homepage = () => {
    const isLoaded = useLoading();
    const [bgPosition, setBgPosition] = useState(0); // State for background position
    const [heightPos, setHeightPos] = useState(0); // State for text box position (0, 1, 2)
    const [isScrolling, setIsScrolling] = useState(false); // Prevent multiple scrolls at the same time

    const textScrollMultiplier = 4;

    const scrollPage = (isDown) => {
        if (isScrolling) return; // Prevent scroll if already scrolling
        setIsScrolling(true);

        let start = null;
        const initialPosition = bgPosition;
        const targetPosition = bgPosition + (isDown ? 50 : -50); // Adjust scroll amount as needed



        // Update text box position
        setHeightPos(heightPos + (isDown ? 1 : -1));

        const easeOutQuad = (t) => t * (2 - t);

        const animate = (timestamp) => {
            if (!start) start = timestamp;
            const progress = (timestamp - start) / 1000; // Normalize the time
            const easeProgress = easeOutQuad(progress); // Apply easing function

            // Update the background position with easing
            setBgPosition(initialPosition + (targetPosition - initialPosition) * easeProgress);

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setIsScrolling(false); // Scroll animation complete
            }
        };

        requestAnimationFrame(animate);
    };

    return (
        <Loader isLoaded={isLoaded}>
        <div className='homepage-container'>
            <div className="text-container">

                {/* Title Box 1 */}
                <div className="text-box-wrapper"  style={{ 
                        transform: `translateY(calc(-${textScrollMultiplier * bgPosition}vh))`,
                    // top: `11vh`
                        }}>
                    <div className="text-box text1">
                        <div className="title-text">
                            <span className="cursive-text">Welcome to <span className="no-wrap-text">Ross Woodworth.com-</span></span><br />explore innovative tools and curated resources to help you navigate the future of finance, technology, and <span className="cursive-text underlined">beyond</span>.
                        </div>
                    </div>
                </div>

                {/* Title Box 2 */}
                <div className="text-box-wrapper">
                    <div className="text-box text2" style={{ 
                        transform: `translateY(calc(-${textScrollMultiplier * bgPosition}vh))`,
                    // top: `calc(${textScrollMultiplier * 40.5}vh)` 
                    }}>
                        <div className="title-text">
                            My mission is to transform the world by increasing access to knowledge and information, empowering a spirit of <span className="cursive-text">curiosity</span> and <span className="cursive-text">innovation</span>.
                        </div>
                    </div>
                </div>
                
                {/* Title Box 3 */}
                <div className="text-box-wrapper">
                    <div className="text-box text3" style={{ 
                        transform: `translateY(calc(-${textScrollMultiplier * bgPosition}vh))`,
                        //top: `calc(${textScrollMultiplier * 81}vh)` 
                        }}>
                        <div className="title-text">
                            <span className="cursive-text">Stay Tuned-</span><br />More content and features are coming soon.
                        </div>
                    </div>
                </div>
            </div>

            {/* Background with dynamic background position */}
            <div className="background" style={{ backgroundPositionY: `${bgPosition}%` }}></div>

            <div className="middleground"></div>
            <div className="foreground"></div>

            {/* Arrow Up - Hidden at top position */}
            {(heightPos > 0) && (!isScrolling) && (
                <div className="up-button">
                    <a onClick={() => scrollPage(false)} className="arrow-button up-arrow">
                        <FontAwesomeIcon icon={faArrowUp} size="2x" />
                    </a>
                </div>
            )}

            {/* Arrow Down - Hidden at bottom position */}
            {(heightPos < 2) && (!isScrolling) && (
                <div className="down-button">
                    <a onClick={() => scrollPage(true)} className="arrow-button down-arrow">
                        <FontAwesomeIcon icon={faArrowDown} size="2x" />
                    </a>
                </div>
            )}
        </div>
            
        </Loader>
    );
};

export default Homepage;
