import Fund from './FundComponents/Fund';
import translations from '../../locales/en';
import Loader from '../Loader/Loader';
import useLoading from '../Loader/useLoading';

function Alternatives() {
    const isLoaded = useLoading();
    return (
        
        <Loader isLoaded={isLoaded}>
            <Fund
            fundId={'alternatives'} 
            fundName={'Alternatives Fund'} 
            fundDescription={translations.fund_alternatives_description} 
            colorA={'rgb(220, 96, 96)'} 
            colorB={'rgb(116, 83, 21)'}/>
        </Loader>
    );
}

export default Alternatives;
