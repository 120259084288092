import React, { useState, useEffect } from 'react';
import './Store.css';
import Loader from '../Loader/Loader';
import useLoading from '../Loader/useLoading';
import ProductCard from './ProductCard';  // Import the ProductCard component

const Store = () => {
  const isLoaded = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');  // New state for search query
  const [sortOption, setSortOption] = useState('bestMatch');  // State for sorting option

  const products = [
    {
      id: 1,
      image: 'https://m.media-amazon.com/images/I/61UZS3QA1UL._SL1500_.jpg',
      title: 'The 48 Laws of Power',
      author: 'Robert Greene',
      price: '24.15',
      rating: '4.7',
      review: 
      `The 48 Laws of Power by Robert Greene is a powerful, eye-opening exploration of the dynamics of power that exist in both personal relationships and broader societal structures. It has fundamentally changed the way I view the world, helping me understand the motivations behind certain behaviors or decisions that, in the past, seemed confusing or irrational. The book lays bare the often hidden forces that drive actions—whether in individual interactions or within larger power systems—and gives clarity to many situations that previously didn’t make sense.
      The structure of the book is both practical and engaging. Each of the 48 laws is introduced with a clear explanation, followed by historical examples from a wide range of time periods and cultures. These examples show how the laws have been used successfully—or broken—with often dramatic consequences. By illustrating the laws through the lens of history, Greene provides rich context that makes each principle not only applicable to today’s world but also deeply rooted in our collective past.
      One of the fascinating aspects of this book is its ability to explain power dynamics in a way that feels both timeless and universally relevant. It’s not just about manipulation, but understanding how power operates and how to protect yourself from being overpowered. I’ve found the book particularly useful in helping me defend myself against individuals who attempted to take advantage of me. The clear signs of manipulation are pointed out throughout the book, making it easier for me to spot these behaviors and avoid falling victim to them.
      That said, The 48 Laws of Power has a certain stigma. Many view it as an “evil” book or a manual for manipulation. While it's true that someone with manipulative tendencies might find the book reinforcing their behavior, Greene himself explains that such individuals likely don’t need the book at all—they already have a certain mindset that the laws merely validate. For the rest of us, the book is about gaining awareness, understanding power dynamics, and defending against exploitation.
      In the end, The 48 Laws of Power isn’t about encouraging manipulation but about offering insights into how power works in the world. It’s a valuable tool for anyone looking to understand human behavior, protect themselves from those who might try to use them, and become more aware of the subtle, yet powerful, forces at play in our lives.
      ~Theodore Woodworth`,
      keywords: ['History', 'Philosophy', 'Political Science', 'Psychology'],
      length: 452,
      hours: 46,
      publishDate: 'January 1, 1998',
      amazonLink: 'https://amzn.to/4i03LRI',
    },
    {
      id: 2,
      image: 'https://m.media-amazon.com/images/I/71udc7ZQtVL._SL1500_.jpg',
      title: 'Four Thousand Weeks',
      author: 'Oliver Burkeman',
      price: '15.49',
      rating: '4.5',
      review: 
      `Four Thousand Weeks by Oliver Burkeman is a thought-provoking exploration of time and how we spend it. The title refers to the average human lifespan of around 4,000 weeks, a sobering reminder of how limited our time truly is. Burkeman does an excellent job making abstract concepts about time tangible and relatable through insightful examples, helping readers understand the fleeting nature of life in a way that’s both engaging and accessible.
      What sets this book apart from traditional time management guides is its twist on the subject. Rather than offering a typical list of productivity hacks or efficiency tips, Burkeman takes a step back to question the underlying motives behind our desire to "manage" time more effectively. He challenges the conventional wisdom of time-management gurus by asking whether trying to optimize every minute of our day actually leads to a more fulfilling life, or whether it just keeps us trapped in a cycle of endless productivity.
      Throughout the book, Burkeman offers refreshing perspectives that invite readers to rethink how they approach time—not just in terms of "getting more done," but in terms of what really matters. It’s a book that encourages you to embrace the limitations of time, rather than fight against them, and to focus on what’s truly worth your energy and attention.
      If you're tired of the typical time-management advice and want a deeper reflection on how to live meaningfully within the constraints of your time, 4000 Weeks is an enlightening read that will change the way you think about your days.
       ~Theodore Woodworth`,
      keywords: ['Philosophy', 'Psychology', 'Self-Improvement', 'Time Management'],
      length: 288,
      hours: 19,
      publishDate: 'August 31, 2021',
      amazonLink: 'https://amzn.to/3QHScTe',
    },
    {
      id: 3,
      image: 'https://m.media-amazon.com/images/I/71vK0WVQ4rL._SL1500_.jpg',
      title: 'How to Win Friends and Influence People',
      author: 'Dale Carnagie',
      price: '9.99',
      rating: '4.7',
      review: 
      `How To Win Friends and Influence People is a practical and effective book to do just what the title says. Each chapter gives you straightforward techniques that you can apply right away, whether you're looking to improve relationships at work, with family, or just in day-to-day interactions.
      The book is not about taking advantage of others—quite the opposite—it’s about fostering trust and valuing others. While it’s easy to think these types of things should come naturally, Carnegie’s approach makes you realize just how important and impactful small actions, like listening actively or giving sincere compliments, can be.
      This book has definitely helped me approach conversations and relationships with more empathy and understanding. If you're looking to improve how you connect with others or simply want a reminder on the power of kindness and respect, this book is a must-read.
       ~Theodore Woodworth`,
      keywords: ['Communication', 'Psychology', 'Self-Improvement'],
      category: 'Self-improvement',
      length: 320,
      hours: 15,
      publishDate: 'October 1, 1998',
      amazonLink: 'https://amzn.to/4kozY6O',
    },
    {
      id: 4,
      image: 'https://m.media-amazon.com/images/I/71piwvkH0sL._SL1500_.jpg',
      title: 'The Intelligent Investor',
      author: 'Benjamin Graham',
      price: '22.73',
      rating: '4.7',
      review: 
      `The Intelligent Investor is a must-read for anyone who manages their own investments. This book has deepened my understanding of the markets and made me a more rational investor.
      Graham’s principles provide a solid foundation for making smarter, long-term investment decisions. One of the best aspects of this book is how it dispels many common myths that unaware investors still fall for today. The idea of chasing trends or trying to get rich quick is debunked in favor of value investing and a disciplined, patient approach. 
      It’s packed with useful insights, so it’s definitely not a light read. I recommend having a notebook and pen handy to jot down key takeaways. It's a book you can return to over and over as you get deeper into investing. Additionally, Jason Zweig’s notes connecting Graham’s principles to real-world events that have happened since the book was first written are invaluable. They help make the timeless advice even more relevant today.
      Overall, if you’re serious about managing your own investments, The Intelligent Investor is a book you can’t afford to miss.
      ~Theodore Woodworth`,
      keywords: ['Business', 'Economics', 'Finance', 'Investing'],
      length: 638,
      hours: 45,
      publishDate: 'January 1, 1949',
      amazonLink: 'https://amzn.to/4kjbwUB',
    }, {
      id: 5,
      image: 'https://m.media-amazon.com/images/I/61LKD6scbfL._SL1500_.jpg',
      title: 'Principles',
      author: 'Ray Dalio',
      price: '18.98',
      rating: '4.6',
      review: 
        `Principles by Ray Dalio is a fascinating and insightful book that truly has two halves. The first half delves into Dalio’s personal story, recounting his life journey and the experiences that shaped his thinking. He explains how he developed the core principles that guided him in building his highly successful investment firm, Bridgewater Associates. This section offers fascinating context, particularly around Dalio’s unique company structure, which he refers to as an "idea meritocracy." This system, where the best ideas rise to the top regardless of hierarchy, plays a pivotal role in Bridgewater’s success, and the book gives readers a behind-the-scenes look at how it operates.
        The second half of Principles shifts focus to the principles themselves—valuable, actionable concepts that Dalio believes can help anyone live a better life. These principles cover a wide range of areas, from personal growth and decision-making to building meaningful relationships and achieving success in both business and life. The real strength of this section lies in its applicability. Dalio emphasizes radical transparency, embracing failure as a learning opportunity, and making decisions based on objective reasoning rather than emotions. These principles are not just theoretical—they are practical tools that can be applied in various situations, from managing teams to personal decision-making.
        What makes these principles particularly valuable is that they offer a framework for navigating the complexities of life. They help readers approach challenges methodically, avoid common pitfalls, and improve their thinking in a more disciplined, thoughtful manner. Whether you're in business, managing relationships, or simply looking to live with greater intentionality, Dalio's principles provide timeless guidance that can be used in any area of life.
        Overall, Principles is both an engaging memoir and a practical guide to better decision-making. Whether you’re interested in learning more about Dalio’s journey or looking to adopt valuable principles to improve your own life, this book offers something for everyone.
        ~Theodore Woodworth`,
      keywords: ['Business', 'Economics', 'Finance', 'Management', 'Philosophy'],
      length: 592,
      hours: 35,
      publishDate: 'September 19, 2017',
      amazonLink: 'https://amzn.to/4igZwB4',
    }, {
      id: 6,
      image: 'https://m.media-amazon.com/images/I/61KaXNCqWJL._SL1063_.jpg',
      title: 'The Wealth of Nations',
      author: 'Adam Smith',
      price: '9.99',
      rating: '4.6',
      review: 
        `The Wealth of Nations by Adam Smith is a highly valuable read for anyone looking to understand how economies function. The book is actually five separate volumes in one, and I recommend getting a well-formatted version (like the one in this listing), as many other editions are poorly printed with tiny text packed tightly on each page, making it nearly impossible to read (trust me, I made this mistake). A readable copy will make a huge difference in your experience, as it allows you to comfortably absorb the vast amount of information Smith presents. This edition stands out with its clear, legible font and good print quality—something that many other editions lack.
        It's also important to note that The Wealth of Nations is written in an older form of English, and many popular editions of the book have been edited or modernized to make the text more accessible. However, this often comes at the expense of the essence and depth of Smith's original work. While the language may seem challenging at times, reading it in its original form offers a richer, more authentic understanding of Smith's ideas.
        The book carefully lays out the fundamental functions of an economy, from the division of labor to the importance of free markets and competition. Smith explains how nations create wealth and how individual actions, driven by self-interest, can lead to overall economic prosperity through the "invisible hand." These concepts are often misunderstood or not well-known today, and Smith’s clear breakdown helps clarify them in a way that applies to modern economies.
        Reading The Wealth of Nations gave me fresh insight into economic patterns I had never noticed before. It solidified my understanding of value creation, trade, and production, as well as the critical role of government in regulating commerce and ensuring fair competition. The book also dives deep into the principles of taxation, international trade, and the effects of monopolies, offering timeless wisdom that continues to shape economic thought.
        If you’re serious about understanding the foundations of modern economics, The Wealth of Nations is a must-read.
        ~Theodore Woodworth`,
      keywords: ['Economics', 'History', 'Philosophy'],
      length: 1004,
      hours: 85,
      publishDate: 'March 9, 1776',
      amazonLink: 'https://amzn.to/41DsUw1', 
    }, {
      id: 7,
      image: 'https://m.media-amazon.com/images/I/51Yg+uqcziL.jpg',
      title: 'The Choice: A Fable of Free Trade and Protectionism',
      author: 'Russell Roberts',
      price: '8.65',
      rating: '4.4',
      review: 
        `The Choice: A Fable of Free Trade and Protectionism offers a highly accessible introduction to the fundamentals of trade policy, making a compelling case for free trade. The book highlights the often misunderstood consequences of trade restrictions, and I highly recommend it to anyone interested in better understanding the impact of these policies.
        The story follows a television manufacturer who journeys through alternate timelines to explore the effects of various economic policies. The author does a fantastic job of explaining complex concepts in an easy-to-understand manner. At just a short and engaging read, this book is perfect for anyone looking to learn without investing much time.
        This book is especially relevant as I write this review, with tariffs expected to be a central focus of the Trump administration’s second term. In the current climate, there’s a lot of debate and misinformation surrounding tariffs and trade policy. The Choice is a great resource to clarify these issues, helping readers form their own informed opinions about what is best for our economy.
        I found the author’s arguments to be well-reasoned and convincing. Initially, I had concerns that important issues were being overlooked. However, by the end of the book, most of my concerns were addressed with compelling analogies and examples from history. There are only two topics I wish had been explored more: the ethics of extreme working conditions, particularly forced labor, and the potential national security risks of disruptive technologies like AI. It’s worth noting that the latest edition of this book was written in 2000, so it’s understandable that these issues weren’t discussed.
        Overall, I found The Choice to be an insightful and thought-provoking read, and I believe others will benefit from it as well.
        ~Theodore Woodworth, March 2025`,
      keywords: ["Economics", "Business", "Political Science", "Philosophy"],
      length: 124,
      hours: 7,
      publishDate: 'October 1, 2000',
      amazonLink: 'https://amzn.to/4kl7rzd', 
    }, {
      id: 8,
      image: 'https://m.media-amazon.com/images/I/51HwNMz3EuL._SL1500_.jpg',
      title: 'The Art of War',
      author: 'Sun Tzu',
      price: '4.99',
      rating: '4.5',
      review: 
        `The Art of War by Sun Tzu is a timeless book of strategy, offering valuable insights for military leaders. While grounded in ancient warfare, its core principles are widely applicable to competitive situations in modern life.
        Some lessons are clearly valuable, like the line “know the enemy and know yourself”. Others seem less relevant, like “If forced to fight in a salt-marsh, you should have water and grass near you, and get your back to a clump of trees.” Key topics of the text include structuring and leading groups effectively, gathering and leveraging information to maximize success, and making timely, decisive choices. 
        While short enough to read in just one or two sittings, The Art of War demands careful study and reflection. I initially found its terminology and poetic structure confusing, but rereading each passage allowed me to grasp the deeper messages. Much of the advice may not seem immediately applicable, but if you can recognize the underlying reasoning behind that advice, you can apply it to any competitive setting.
        Readers looking for a quick read who are not interested in deep analysis will still find a wealth of practical, actionable insights, whether their “war” unfolds in the office, sports field, or elsewhere.
        ~ Theodore Woodworth, March 2025`,
      keywords: ["Leadership", "Philosophy", "Psychology", "Game Theory", "Political Science"],
      length: 68,
      hours: 4,
      publishDate: 'circa 500 BC',
      amazonLink: 'https://amzn.to/4l3IbOv', 
    }
  ];

  const shuffleArray = (array) => {
    let shuffledArray = [...array]; // Make a copy to avoid mutating the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
    }
    return shuffledArray;
  };

  useEffect(() => {
    setSearchResults(shuffleArray(products));
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    sortItems(event.target.value);
  };

  const sortItems = (option) => {
    let sortedItems;
    switch (option) {
      case 'highestPrice':
        sortedItems = [...searchResults].sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        break;
      case 'lowestPrice':
        sortedItems = [...searchResults].sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        break;
      case 'shortestLengthPages':
        sortedItems = [...searchResults].sort((a, b) => a.length - b.length);
        break;
      case 'longestLengthPages':
        sortedItems = [...searchResults].sort((a, b) => b.length - a.length);
        break;
      case 'shortestLengthHours':
        sortedItems = [...searchResults].sort((a, b) => a.hours - b.hours);
        break;
      case 'longestLengthHours':
        sortedItems = [...searchResults].sort((a, b) => b.hours - a.hours);
        break;
      case 'highestRating':
        sortedItems = [...searchResults].sort((a, b) => b.rating - a.rating);
        break;
      case 'A-Z':
        sortedItems = [...searchResults].sort((a, b) => {
          // Remove "The " from the beginning of the title if it exists
          const titleA = a.title.replace(/^The\s+/i, '');
          const titleB = b.title.replace(/^The\s+/i, '');
          return titleA.localeCompare(titleB);
        });
        break;
      
      case 'Z-A':
        sortedItems = [...searchResults].sort((a, b) => {
          // Remove "The " from the beginning of the title if it exists
          const titleA = a.title.replace(/^The\s+/i, '');
          const titleB = b.title.replace(/^The\s+/i, '');
          return titleB.localeCompare(titleA);
        });
        break;
      case 'random':
        sortedItems = shuffleArray([...searchResults])
        break;
      default:
        sortedItems = shuffleArray([...searchResults])
        break;
    }
    setSearchResults(sortedItems);
  };

  const filteredResults = searchResults.filter((product) => {
    const searchQueryLower = searchQuery.toLowerCase();
    return (
      product.title.toLowerCase().includes(searchQueryLower) ||
      product.author.toLowerCase().includes(searchQueryLower) ||
      product.keywords.some(keyword =>
        keyword.toLowerCase().includes(searchQueryLower)
      )
    );
  });

  // Function to open the modal with the clicked image
  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage('');
  };

  return (
    <Loader isLoaded={isLoaded}>
      <div className="store-container">
        <div className="background"></div>

        {/* Modal for enlarged image */}
        {showModal && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content">
              <img src={modalImage} alt="Expanded Product" className="modal-image" />
            </div>
          </div>
        )}

        {/* Product Listings */}
        <div className="product-list">
          {/* Search bar with the sort dropdown */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for title, author, category..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            
            {/* Sort Dropdown */}
            <select
              value={sortOption}
              onChange={handleSortChange}
              style={{ padding: '8px', marginLeft: '10px' }}
            >
              <option value="default">Sort by...</option>
              <option value="lowestPrice">Lowest Price</option>
              <option value="highestPrice">Highest Price</option>
              <option value="shortestLengthPages">Shortest Length</option>
              <option value="longestLengthPages">Longest Length</option>
              <option value="shortestLengthHours">Shortest Read Time</option>
              <option value="longestLengthHours">Longest Read Time</option>
              <option value="highestRating">Customer Reviews</option>
              <option value="A-Z">A-Z</option>
              <option value="Z-A">Z-A</option>
              <option value="random">Random</option>
            </select>

            
            <div className="results-count">
              <p>{filteredResults.length} {filteredResults.length === 1 ? 'result found' : 'results found'}</p>
            </div>
          </div>


          {/* Displaying filtered and sorted results */}
          {filteredResults.map((result) => (
            <ProductCard
              key={result.id}
              image={result.image}
              title={result.title}
              author={result.author}
              price={result.price}
              review={result.review}
              keywords={result.keywords}
              rating={result.rating}
              length={result.length}
              publishDate={result.publishDate}
              amazonLink={result.amazonLink}
              hours={result.hours}
              openModal={openModal} // Pass down the openModal function
            />
          ))}
        </div>
      </div>
    </Loader>
  );
};

export default Store;
