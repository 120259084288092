import Fund from './FundComponents/Fund.js';
import  translations from '../../locales/en.js';

import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

function Income() {
    const isLoaded = useLoading();
    return (
        
        <Loader isLoaded={isLoaded}>
            <Fund
        fundId={'income'} 
        fundName={'Income Fund'} 
        fundDescription={translations.fund_income_description} 
        colorA={'rgb(27, 211, 43)'} 
        colorB={'rgb(11, 136, 79)'}/>
        </Loader>
    );
}

export default Income;
