import React, { useEffect, useState } from 'react';
import Graph from './Graph';
import './Fund.css';

function Fund({fundId, fundName, fundDescription, colorA, colorB}) {
  const [fundData, setFundData] = useState(null);

  useEffect(() => {
    fetch('https://api.rosswoodworth.com/funds/' + fundId)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setFundData(data);
      })
      .catch((error) => {
        console.error('Error fetching fund data:', error);
      });
  }, []);

  if (!fundData) {
    return <div>Loading...</div>;
  }

  
  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const formatPercentage = (value) => {
    if (value === null || value === undefined) {
      return "-";
    }
    return `${(value * 100).toFixed(2)}%`;
  };

  return (
    <div className="fund-container" 
        style={{
            background: `radial-gradient(circle at top left, ${colorA}, ${colorB})`, 
        }}>
  <div className="header">
    <span className="page-title">{fundName}</span>
    <p className="caption">{fundDescription}</p>
  </div>

  <div className="overview-container">
    <div className="stat-box">
      <span className="stat-label">Inception Date</span>
      <span className="stat-value">{formatDate(fundData.stats.inception)}</span>
    </div>
    <div className="stat-box">
      <span className="stat-label">Asset Class</span>
      <span className="stat-value">{fundData.stats.asset_class.charAt(0).toUpperCase() + fundData.stats.asset_class.slice(1)}</span>
    </div>
    <div className="stat-box">
      <span className="stat-label">CAGR</span>
      <span className="stat-value">{formatPercentage(fundData.stats.cagr)}</span>
    </div>
    <div className="stat-box">
      <span className="stat-label">Volatility</span>
      <span className="stat-value">{formatPercentage(fundData.stats.volatility)}</span>
    </div>
    <div className="stat-box">
      <span className="stat-label">Max Drawdown</span>
      <span className="stat-value">{formatPercentage(fundData.stats.max_drawdown)}</span>
    </div>
    <div className="stat-box">
      <span className="stat-label">YTD Returns</span>
      <span className="stat-value">{formatPercentage(fundData.stats.ytd)}</span>
    </div>
  </div>

  <div className="graph-table-container">
    <section className="holdings-breakdown">
      <div className="table-container">
        <table className="holdings-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Portfolio Weight</th>
            </tr>
          </thead>
          <tbody>
            {fundData.holdings.map((holding, index) => (
              <tr key={index}>
                <td className="category-cell">{holding.asset_class}</td>
                <td className="allocation-cell">{formatPercentage(holding.total_allocation)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
    
    <div className="graph-container">
      <Graph 
        returnsData={fundData.returns} 
        colorA={colorA} 
        colorB={colorB} 
        />
    </div>
    
    {/* Holdings table */}
    
  </div>
</div>

  );
}

export default Fund;
