import React from 'react';
import './Tools.css';
import Loader from '../Loader/Loader';
import useLoading from '../Loader/useLoading';
import { Link } from 'react-router-dom';

const Tools = () => {
  const isLoaded = useLoading();

  const categories = [
    {
      name: 'Portfolio Management',
      tools: [
        { name: 'Markowitz Efficient Frontier Calculator', path: '/tools/efficient-frontier' }, // Active, keep
        { name: 'Asset Correlation Calculator (Coming Soon)', path: '', status: 'coming-soon' }, // Useful for diversification
        { name: 'Black-Litterman Model (Coming Soon)', path: '', status: 'coming-soon' }, // Impressive, advanced model
        { name: 'Monte Carlo Simulation (Coming Soon)', path: '', status: 'coming-soon' }, // Impressive, widely recognized
        { name: 'Value-at-Risk Calculator (Coming Soon)', path: '', status: 'coming-soon' }, // Useful risk metric
        // { name: 'Capital Asset Pricing Model (Coming Soon)', path: '', status: 'coming-soon' }, // Basic, less unique
        // { name: 'Fama-French Multi-Factor Model (Coming Soon)', path: '', status: 'coming-soon' }, // Niche, less immediate appeal
        // { name: 'Portfolio Backtester (Coming Soon)', path: '', status: 'coming-soon' }, // Useful but generic
        // { name: 'Portfolio Optimizer (Coming Soon)', path: '', status: 'coming-soon' }, // Overlaps with Markowitz
        // { name: 'Principal Component Analysis (Coming Soon)', path: '', status: 'coming-soon' }, // Too technical for most
        // { name: 'Robust Optimization (Coming Soon)', path: '', status: 'coming-soon' }, // Niche, less known
      ],
    },
    {
      name: 'Personal Finance',
      tools: [
        { name: 'Budget Calculator (Coming Soon)', path: '' }, // Everyday utility
        { name: 'Debt Payoff Calculator (Coming Soon)', path: '' }, // Broad appeal
        { name: 'Mortgage Calculator (Coming Soon)', path: '' }, // Common need
        { name: 'Retirement Calculator (Coming Soon)', path: '' }, // Long-term planning
        { name: 'Savings Goal Calculator (Coming Soon)', path: '' }, // Practical goal-setting
        // { name: 'Credit Card Payoff Calculator (Coming Soon)', path: '' }, // Overlaps with Debt Payoff
      ],
    },
    {
      name: 'Investment Analysis',
      tools: [
        { name: 'Compound Interest Calculator', path: '/tools/compound-interest' }, // Fundamental, useful
        { name: 'Black-Scholes Options Pricing Calculator (Coming Soon)', path: '' }, // Impressive, widely known
        { name: 'Discounted Cash Flow Calculator (Coming Soon)', path: '' }, // Core valuation tool
        { name: 'Return on Investment Calculator (Coming Soon)', path: '' }, // Broad appeal
        { name: 'Sharpe Ratio Calculator (Coming Soon)', path: '' }, // Key performance metric
        // { name: 'Annuity Calculator (Coming Soon)', path: '' }, // Niche
        // { name: 'Bond Yield Calculator (Coming Soon)', path: '' }, // Specific use
        // { name: 'Dividend Yield Calculator (Coming Soon)', path: '' }, // Basic, less standout
        // { name: 'Perpetuity Calculator (Coming Soon)', path: '' }, // Niche
        // { name: 'Profit Margin Calculator (Coming Soon)', path: '' }, // Business-focused
        // { name: 'Stock Valuation Calculator (Coming Soon)', path: '' }, // Overlaps with DCF
      ],
    },
    {
      name: 'Economics & Markets',
      tools: [
        { name: 'Currency Conversion Calculator (Coming Soon)', path: '' }, // Practical, global use
        { name: 'Inflation Calculator (Coming Soon)', path: '' }, // Useful for economic context
      ],
    },
    {
      name: 'Math & Statistics',
      tools: [
        { name: 'Correlation Calculator (Coming Soon)', path: '' }, // Useful in finance/stats
        { name: 'Graphing Calculator (Coming Soon)', path: '' }, // Impressive, visual appeal
        { name: 'Dataset Statistics Calculator (Coming Soon)', path: '' }, // Fundamental stats
        { name: 'Probability Calculator (Coming Soon)', path: '' }, // Broad math utility
        { name: 'Regression Analysis Tool (Coming Soon)', path: '' }, // Advanced, impressive
        // { name: 'Combination / Permutation Calculator (Coming Soon)', path: '' }, // Niche
        // { name: 'Greatest Common Denominator Calculator (Coming Soon)', path: '' }, // Basic
        // { name: 'Least Common Multiple Calculator (Coming Soon)', path: '' }, // Basic
        // { name: 'Percentage Change Calculator (Coming Soon)', path: '' }, // Simple
        // { name: 'Z-Score Calculator (Coming Soon)', path: '' }, // Niche stats
      ],
    },
    {
      name: 'Miscellaneous',
      tools: [
        { name: 'Age Calculator (Coming Soon)', path: '' }, // Fun, simple
        { name: 'Unit Conversion Calculator (Coming Soon)', path: '' }, // Practical utility
      ],
    },
  ];

  return (
    <Loader isLoaded={isLoaded}>
      <div className="tools-container">
        <div className="background"></div>
        <div className="tools-grid">
          {categories.map((category, idx) => (
            <div key={idx} className="category-box">
              <h2 className="category-title">{category.name}</h2>
              <ul className="tools-list">
                {category.tools.map((tool, toolIdx) => (
                  <li key={toolIdx} className="tool-item">
                    {tool.path !== '' ? (
                      <Link to={tool.path} className="tool-link">
                        {tool.name}
                      </Link>
                    ) : (
                      <span className="tool-link under-development">
                        {tool.name}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Loader>
  );
};

export default Tools;