import React, { useEffect, useState }  from 'react';
import './Funds.css';
import { useNavigate } from 'react-router-dom';
import translations from '../../locales/en.js';
import Loader from '../Loader/Loader.js';
import useLoading from '../Loader/useLoading.js';

const Funds = () => {
  const isLoaded = useLoading();
  const [funds, setFunds] = useState([]);
  const navigate = useNavigate();

  const handleRowClick = (link) => {
    navigate(link);
  };

  useEffect(() => {
    fetch('https://api.rosswoodworth.com/funds/')
      .then((response) => response.json())
      .then((data) => {
        setFunds(data);  // Store the data in state
      })
      .catch((error) => console.error('Error fetching funds:', error));
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const formatPercentage = (value) => {
    if (value === null || value === undefined) {
      return "-";
    }
    return `${(value * 100).toFixed(2)}`;
  };

  return (
    <Loader isLoaded={isLoaded}>
    <div className="funds-container">
      <div className="header">
        <span className="page-title">{translations.funds_title}</span>
        <p className="caption">{translations.funds_caption}</p>
      </div>
      <div className="table-wrapper">
        <table className="fund-table">
          <thead>
            <tr>
              <th>{translations.funds_table_name}</th>
              <th>{translations.funds_table_asset_class}</th>
              <th>{translations.funds_table_inception}</th>
              <th className="numeric">YTD (%)</th>
              <th className="numeric">1Y (%)</th>
              <th className="numeric">2Y (%)</th>
              <th className="numeric">CAGR (%)</th>
              <th className="numeric">{translations.funds_table_volatility} (%)</th>
            </tr>
          </thead>
          <tbody>
            {funds
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((fund) => (
              <tr key={fund.name} onClick={() => handleRowClick('/funds/' + fund.name)}>
                <td data-label={translations.funds_table_name}>{fund.name.charAt(0).toUpperCase() + fund.name.slice(1)} <i className="fa fa-chevron-right link-icon"></i></td>
                <td data-label={translations.funds_table_asset_class}>{fund.asset_class}</td>
                <td data-label={translations.funds_table_inception}>{formatDate(fund.inception)}</td>
                <td className="numeric" data-label="YTD (%)">{formatPercentage(fund.ytd)}</td>
                <td className="numeric" data-label="1Y (%)">{formatPercentage(fund.returns_1y)}</td>
                <td className="numeric" data-label="2Y (%)">{formatPercentage(fund.returns_2y)}</td>
                <td className="numeric" data-label="CAGR (%)">{formatPercentage(fund.cagr)}</td>
                <td className="numeric" data-label={translations.funds_table_volatility + " (%)"}>{formatPercentage(fund.volatility)}</td>
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
    </Loader>
  );
};

export default Funds;
