import React, { useState } from 'react';
import './ProductCard.css';

const ProductCard = ({
  image,
  title,
  author,
  price,
  rating,
  review,
  keywords,
  length,
  hours,
  publishDate,
  amazonLink,
  openModal, // Receive the openModal function
}) => {
  const [expanded, setExpanded] = useState(false);
  const [zoomedIn, setZoomedIn] = useState(false); // State to control zoom in/out

  const handleExpand = () => setExpanded(!expanded);

  const handleImageClick = (e) => {
    e.stopPropagation(); // Prevent the click from closing the modal
  };

  const formatReview = (text) => {
    return text.split('\n').map((item, index) => (
      <p key={index}>{item}</p>
    ));
  };

  return (
    <div className="product-card">
        <div className="row">
          <div className="column">
            
            <div className="product-image" onClick={() => openModal(image)}>
              <img src={image} alt={title} />
              <div className="expand-icon">
                <i className="fas fa-expand"></i>
              </div>
            </div>
            
            <a href={amazonLink} target="_blank" rel="noopener noreferrer">
              <button className="purchase-button">Buy for <b>${price}</b></button>
            </a>
          </div>
          <div className="column">
            <div className="row title-row">
                <span className="title">{title}</span>
            </div>
            <div className="row author-row">
              <span className="author">by {author}</span>
            </div>

            <div className="row">
              
            <div className="product-info">
                {/* Purchase Button */}
                <div className="row">
                  
                  {/* Additional Information (Category, Keywords, Bio, Length, Date) */}
                  <div className="additional-info">
                        <div className="category">
                        <strong>Categories:</strong> {keywords.join(', ')}
                        </div>
                        <div className="book-length">
                        <strong>Length:</strong> {length} pages ({hours} hours of reading)
                        </div>
                        <div className="publish-date">
                        <strong>Published:</strong> {publishDate}
                        </div>
                        <div className="rating">
                        <strong>Amazon Reviews:</strong> {rating} / 5.0
                        </div>
                    </div>
                </div>
                <div className="disclaimer">*Disclaimer: As a participant in the Amazon Associate program, we earn commissions from qualifying purchases of this book.</div>
            </div>
            </div>
          </div>
                
        </div>
         <div className="column">

          <div className={`product-review ${expanded ? 'expanded' : ''}`}>
            <p><span className="review-start">Review: </span>{expanded ? formatReview(review) : review.slice(0, 140)}{expanded ? '' : '...'}</p>
          </div>
          
          <button className="product-review-button" onClick={handleExpand}>
              {expanded ? 'Read less' : 'Read more'}
            </button>

        </div>
        

      {/* Modal to show the enlarged image */}
      <div className="modal-overlay" style={{ display: zoomedIn ? 'flex' : 'none' }}>
        <div className="modal-content">
          <img
            src={image}
            alt={title}
            className="modal-image"
            style={{
              transform: zoomedIn ? 'scale(1)' : 'scale(0.3)', // Apply zoom based on state
            }}
            onClick={handleImageClick} // Click on image will toggle zoom
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
