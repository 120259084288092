import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './EpisodePage.css';
import DynamicLoader from '../DynamicLoader/DynamicLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faExpand, faCog, faVolumeUp, faVolumeMute, faForward, faBackward, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

function EpisodePage({ episodes }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [episode, setEpisode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoMode, setIsVideoMode] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [showSettings, setShowSettings] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [isTouchActive, setIsTouchActive] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [lastMouseMove, setLastMouseMove] = useState(Date.now()); // Track last mouse movement
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const placeholderRef = useRef(null);

  useEffect(() => {
    if (episodes.length === 0) {
      setLoading(true);
      return;
    }

    const foundEpisode = episodes.find(ep => ep.slug === slug);
    if (foundEpisode) {
      setEpisode(foundEpisode);
      if (foundEpisode.enclosure && foundEpisode.enclosure.endsWith('.mp4')) {
        setHasVideo(true);
        setIsVideoMode(true);
      } else {
        setHasVideo(false);
        setIsVideoMode(false);
      }
    } else {
      navigate('/podcast', { replace: true });
    }
    setLoading(false);
  }, [slug, episodes, navigate]);

  useEffect(() => {
    let timeout;

    const handleControlsVisibility = () => {
      if (!isPlaying) {
        // Always show controls when paused
        setShowControls(true);
        clearTimeout(timeout);
      } else {
        // Show controls initially when playing
        setShowControls(true);
        // Check inactivity every 100ms
        timeout = setInterval(() => {
          const timeSinceLastMove = Date.now() - lastMouseMove;
          if (timeSinceLastMove >= 2500 && !isTouchActive) {
            setShowControls(false); // Fade out after 2.5s of inactivity
          } else {
            setShowControls(true); // Keep visible if mouse moved recently
          }
        }, 100);
      }
    };

    handleControlsVisibility();

    return () => clearInterval(timeout); // Use clearInterval since we’re using setInterval
  }, [isPlaying, lastMouseMove, isTouchActive]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setLastMouseMove(Date.now()); // Reset timer on enter
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setLastMouseMove(Date.now()); // Reset timer on leave (optional, could remove if only movement matters)
  };

  const handleMouseMove = () => {
    setLastMouseMove(Date.now()); // Update timestamp on any mouse movement
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVideoClick = () => {
    if (isTouchActive && showControls) {
      setIsPlaying(!isPlaying);
    } else {
      setIsTouchActive(true);
      setShowControls(true);
      const timeout = setTimeout(() => {
        setIsTouchActive(false);
        if (isPlaying && Date.now() - lastMouseMove >= 2500) {
          setShowControls(false);
        }
      }, 2500);
      return () => clearTimeout(timeout);
    }
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleResolutionChange = (mode) => {
    if (mode === 'audio') {
      setIsVideoMode(false);
    } else {
      setIsVideoMode(true);
    }
    setShowSettings(false);
    setIsPlaying(false);
  };

  const handleFullscreen = () => {
    const element = isVideoMode && hasVideo ? videoRef.current : placeholderRef.current;
    if (element && document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch(err => console.error('Fullscreen error:', err));
      } else {
        document.exitFullscreen();
      }
    }
  };

  const handleTimeUpdate = (state) => {
    if (!isSeeking) {
      setCurrentTime(state.playedSeconds);
    }
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value / 100) * duration;
    setCurrentTime(seekTime);
  };

  const handleSeekEnd = (e) => {
    const seekTime = (e.target.value / 100) * duration;
    const player = isVideoMode && hasVideo ? videoRef.current : audioRef.current;
    if (player) {
      player.seekTo(seekTime, 'seconds');
    }
    setCurrentTime(seekTime);
    setIsSeeking(false);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
    if (isMuted) setIsMuted(false);
  };

  const handleSkipBack = () => {
    setCurrentTime(prev => {
      const newTime = Math.max(prev - 15, 0);
      const player = isVideoMode && hasVideo ? videoRef.current : audioRef.current;
      if (player) player.seekTo(newTime);
      return newTime;
    });
  };

  const handleSkipForward = () => {
    setCurrentTime(prev => {
      const newTime = Math.min(prev + 15, duration);
      const player = isVideoMode && hasVideo ? videoRef.current : audioRef.current;
      if (player) player.seekTo(newTime);
      return newTime;
    });
  };

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  if (loading) {
    return <DynamicLoader message="Loading episode..." />;
  }

  if (!episode) {
    return null;
  }

  const mediaUrl = episode.enclosure || episode.link;
  const guestSuffix = episode.guest !== 'Unknown' ? ` - ${episode.guest}` : '';

  return (
    <div className="episode-page">
      <div className="background"></div>
      <button className="back-button" onClick={() => navigate('/podcast')}>
        <FontAwesomeIcon icon={faArrowLeft} /> All Episodes
      </button>
      <div
        className="media-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove} // Track mouse movement
      >
        {loading && <DynamicLoader />}
        {!mediaUrl ? (
          <div className="media-error">
            <p>Media unavailable. Please try another episode.</p>
          </div>
        ) : isVideoMode && hasVideo ? (
          <ReactPlayer
            ref={videoRef}
            url={mediaUrl}
            playing={isPlaying}
            volume={isMuted ? 0 : volume}
            playbackRate={playbackRate}
            width="100%"
            height="auto"
            onProgress={handleTimeUpdate}
            onDuration={setDuration}
            config={{ file: { attributes: { poster: episode.thumbnail } } }}
            onClick={handleVideoClick}
            className="react-player"
            preload="auto"
          />
        ) : (
          <div className="media-placeholder" ref={placeholderRef} onClick={handleVideoClick}>
            <img src={episode.thumbnail} alt={episode.title} className="podcast-icon" />
            <ReactPlayer
              ref={audioRef}
              url={mediaUrl}
              playing={isPlaying}
              volume={isMuted ? 0 : volume}
              playbackRate={playbackRate}
              width="0"
              height="0"
              onProgress={handleTimeUpdate}
              onDuration={setDuration}
              preload="auto"
            />
          </div>
        )}
        <div className={`controls-overlay ${showControls ? 'show' : 'hide'}`}>
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100 || 0}
            onMouseDown={handleSeekStart}
            onChange={handleSeek}
            onMouseUp={handleSeekEnd}
            onTouchStart={handleSeekStart}
            onTouchMove={handleSeek}
            onTouchEnd={handleSeekEnd}
            className="progress-bar"
            style={{ '--progress': `${(currentTime / duration) * 100 || 0}%` }}
          />
          <div className="controls-inner">
            <div className="left-controls">
              <button className="control-btn" onClick={handlePlayPause}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </button>
              <div className="volume-control">
                <button className="control-btn" onClick={handleMuteToggle}>
                  <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                </button>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="volume-slider"
                />
              </div>
            </div>
            <div className="right-controls">
              <span className="time-display">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
              <button className="control-btn" onClick={handleSkipBack}>
                <FontAwesomeIcon icon={faBackward} />
              </button>
              <button className="control-btn" onClick={handleSkipForward}>
                <FontAwesomeIcon icon={faForward} />
              </button>
              <div className="settings-menu">
                <button className="control-btn" onClick={() => setShowSettings(!showSettings)}>
                  <FontAwesomeIcon icon={faCog} />
                </button>
                {showSettings && (
                  <div className="settings-dropdown">
                    <button onClick={() => handleResolutionChange('audio')} className="settings-option">
                      Audio Only
                    </button>
                    <button
                      onClick={() => handleResolutionChange('360p')}
                      className="settings-option"
                      disabled={!hasVideo}
                    >
                      360p
                    </button>
                    <button
                      onClick={() => handleResolutionChange('720p')}
                      className="settings-option"
                      disabled={!hasVideo}
                    >
                      720p
                    </button>
                    <button
                      onClick={() => handleResolutionChange('1080p')}
                      className="settings-option"
                      disabled={!hasVideo}
                    >
                      1080p
                    </button>
                    <div className="speed-options">
                      <label>Speed:</label>
                      {[0.5, 0.75, 1, 1.25, 1.5, 2].map((rate) => (
                        <button
                          key={rate}
                          onClick={() => handlePlaybackRateChange(rate)}
                          className="settings-option"
                        >
                          {rate}x
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <button className="control-btn" onClick={handleFullscreen}>
                <FontAwesomeIcon icon={faExpand} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="episode-details">
        <h1 className="episode-title">
          Episode {episode.episodeNumber}{guestSuffix}
        </h1>
        <p><strong>Release Date:</strong> {episode.pubDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
        <p><strong>Topics:</strong> {episode.topics.length > 0 ? episode.topics.join(', ') : 'N/A'}</p>
        <p><strong>Overview:</strong> {episode.overview}</p>
      </div>
    </div>
  );
}

export default EpisodePage;