import React, { useState } from 'react';
import './EfficientFrontier.css';
import DatePicker from 'react-datepicker';
import Slider from 'react-slider';
import { Chart as ChartJS, ScatterController, LineController, PointElement, LineElement, ArcElement, Tooltip, Legend } from 'chart.js';
import { Scatter, Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../Loader/Loader';
import useLoading from '../../../Loader/useLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DynamicLoader from '../../../DynamicLoader/DynamicLoader';

// Disable tooltips globally for all charts (we'll override per chart)
ChartJS.defaults.plugins.tooltip.enabled = false;

ChartJS.register(ScatterController, LineController, PointElement, LineElement, ArcElement, Tooltip, Legend);

const EfficientFrontier = () => {
  const isLoaded = useLoading();

  // State for configuration
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [useFullHistory, setUseFullHistory] = useState(false);
  const [uncertaintyFactor, setUncertaintyFactor] = useState(0);
  const [minTargetReturn, setMinTargetReturn] = useState('');
  const [maxTargetReturn, setMaxTargetReturn] = useState('');
  const [riskFreeRate, setRiskFreeRate] = useState(3.0);
  const [showCML, setShowCML] = useState(false);
  const [marginRate, setMarginRate] = useState(0);

  // State for assets and results
  const [assets, setAssets] = useState(Array(10).fill({ ticker: '', minWeight: '', maxWeight: '', expectedReturn: '' }));
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disclosureOpen, setDisclosureOpen] = useState(false);
  const [overviewOpen, setOverviewOpen] = useState(false); // New state for overview toggle

  // Handle asset input changes
  const handleAssetChange = (index, field, value) => {
    setAssets(prevAssets => {
      const newAssets = [...prevAssets];
      newAssets[index] = { ...newAssets[index], [field]: value };
      return newAssets;
    });
  };

  // Add more rows
  const addMoreRows = () => {
    setAssets([...assets, ...Array(10).fill({ ticker: '', minWeight: '', maxWeight: '', expectedReturn: '' })]);
  };

  // Clear all rows
  const clearAssets = () => {
    setAssets(Array(10).fill({ ticker: '', minWeight: '', maxWeight: '', expectedReturn: '' }));
  };

  // Validate and submit
  const handleSubmit = async () => {
    const tickers = assets.filter(a => a.ticker.trim()).map(a => a.ticker.trim());
  
    if (tickers.length < 2) {
      alert('Please enter at least two distinct asset tickers.');
      return;
    }
  
    const tickerSet = new Set(tickers.map(t => t.toUpperCase()));
    if (tickerSet.size !== tickers.length) {
      alert('Duplicate asset tickers are not allowed.');
      return;
    }
  
    const totalMinWeight = assets.reduce((sum, asset) => sum + (parseFloat(asset.minWeight) || 0), 0);
    if (totalMinWeight > 100) {
      alert('Sum of minimum weights cannot exceed 100%.');
      return;
    }
    for (const asset of assets.filter(a => a.ticker.trim())) {
      const minW = asset.minWeight.trim() ? parseFloat(asset.minWeight) : null;
      const maxW = asset.maxWeight.trim() ? parseFloat(asset.maxWeight) : null;
      if (minW !== null && maxW !== null && minW > maxW) {
        alert(`Minimum weight for ${asset.ticker} (${minW}%) cannot exceed its maximum weight (${maxW}%).`);
        return;
      }
    }
  
    const hasUnspecifiedMax = assets.some(a => a.ticker.trim() && !a.maxWeight.trim());
    if (!hasUnspecifiedMax) {
      const totalMaxWeight = assets.reduce((sum, asset) => {
        const maxW = parseFloat(asset.maxWeight);
        return sum + (isNaN(maxW) ? 0 : maxW);
      }, 0);
      if (totalMaxWeight < 100) {
        alert('Sum of maximum weights is less than 100%, making a full 100% allocation impossible.');
        return;
      }
    }
  
    const tickerBounds = assets.reduce((bounds, asset) => {
      if (asset.ticker.trim()) {
        bounds[asset.ticker.trim()] = [
          asset.minWeight.trim() ? parseFloat(asset.minWeight) / 100 : null,
          asset.maxWeight.trim() ? parseFloat(asset.maxWeight) / 100 : null
        ];
      }
      return bounds;
    }, {});
    const expectedReturns = assets.reduce((returns, asset) => {
      if (asset.ticker.trim() && asset.expectedReturn.trim()) {
        returns[asset.ticker.trim()] = parseFloat(asset.expectedReturn) / 100;
      }
      return returns;
    }, {});
  
    const requestBody = {
      tickers,
      ticker_bounds: tickerBounds,
      risk_free_rate: riskFreeRate / 100,
      uncertainty_factor: uncertaintyFactor,
      use_shortest_history: !useFullHistory,
      min_target_return: minTargetReturn.trim() ? parseFloat(minTargetReturn) / 100 : null,
      max_target_return: maxTargetReturn.trim() ? parseFloat(maxTargetReturn) / 100 : null,
      start_date: startDate ? startDate.toISOString().split('T')[0] : null,
      end_date: endDate ? endDate.toISOString().split('T')[0] : null,
      expected_returns: expectedReturns,
      cml: showCML,
      margin_rate: marginRate / 100
    };
  
    setLoading(true);
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 180000); // 2 minute timeout
      const response = await fetch('https://api.rosswoodworth.com/tools/efficient-frontier', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });
      clearTimeout(timeoutId);
      const data = await response.json();
      if (data.error) {
        alert(data.error);
      } else {
        setResults(data);
      }
    } catch (error) {
      alert('Error fetching results: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const frontierChartData = results ? {
    datasets: [
      {
        label: 'Efficient Frontier',
        data: results.frontier.map(p => ({ x: p.risk * 100, y: p.return * 100 })),
        borderColor: 'rgba(0, 0, 0, 0)',
        pointBackgroundColor: results.frontier.map(p => {
          const sharpeValues = results.frontier.map(p => p.sharpe).sort((a, b) => a - b);
          const n = sharpeValues.length;
          const p100 = sharpeValues[n - 1];
          const p76 = sharpeValues[Math.floor(n * 0.76)]; 
          const p10 = sharpeValues[Math.floor(n * 0.10)];
  
          let r, g, b;
          const sharpe = p.sharpe;
  
          if (sharpe >= p76) {
            const t = (sharpe - p76) / (p100 - p76) || 0;
            r = Math.round(255 * (1-t));
            g = Math.round(255);
            b = Math.round(0);
          } else if (sharpe >= p10) {
            const t = (sharpe - p10) / (p76 - p10) || 0;
            r = Math.round(255);
            g = Math.round(255 * t);
            b = Math.round(0);
          } else {
            r = 255;
            g = 0;
            b = 0;
          }
  
          return `rgb(${r}, ${g}, ${b})`;
        }),
        pointRadius: 3,
        showLine: true,
        tension: 0.4,
        tooltip: {
          callbacks: {
            label: (context) => {
              const p = results.frontier[context.dataIndex];
              const allocationLines = Object.entries(p.weights)
                .filter(([_, weight]) => weight > 0.00005)
                .sort((a, b) => b[1] - a[1])
                .map(([ticker, weight]) => `${ticker}: ${(weight * 100).toFixed(2)}%`);
              return [
                'Efficient Frontier Portfolio',
                ...allocationLines,
                '',
                `Expected Return: ${(p.return * 100).toFixed(2)}%`,
                `Standard Deviation: ${(p.std_dev * 100).toFixed(2)}%`,
                uncertaintyFactor > 0 && useFullHistory ? `Uncertainty: ${(p.uncertainty * 100).toFixed(2)}%` : '',
                uncertaintyFactor > 0 && useFullHistory ? `Total Risk: ${(p.risk * 100).toFixed(2)}%` : '',
                `Sharpe Ratio: ${p.sharpe.toFixed(2)}`
              ].filter(line => line);
            }
          }
        }
      },
      {
        label: 'Individual Assets',
        data: results.assets.map(a => ({
          x: a.risk * 100,
          y: a.return * 100
        })),
        borderColor: 'rgba(0, 0, 0, 0)',
        pointBackgroundColor: 'rgba(220, 100, 255, 1)',
        pointRadius: 5,
        showLine: false,
        tooltip: {
          callbacks: {
            label: (context) => {
              const a = results.assets[context.dataIndex];
              return [
                `${a.ticker} (${a.name || 'Asset'})`,
                '',
                `Expected Return: ${(a.return * 100).toFixed(2)}%`,
                `Standard Deviation: ${(a.std_dev * 100).toFixed(2)}%`,
                uncertaintyFactor > 0 && useFullHistory ? `Uncertainty: ${(a.uncertainty * 100).toFixed(2)}%` : '',
                uncertaintyFactor > 0 && useFullHistory ? `Total Risk: ${(a.risk * 100).toFixed(2)}%` : '',
                `Sharpe Ratio: ${a.sharpe.toFixed(2)}`
              ].filter(line => line);
            }
          }
        }
      },
      ...(showCML && results.cml ? [{
        label: 'Capital Market Line',
        data: results.cml.map(p => ({ x: p.risk * 100, y: p.return * 100 })),
        borderColor: 'rgba(128, 128, 128, 0.5)',
        pointBackgroundColor: 'rgba(128, 128, 128, 0.8)',
        pointRadius: 3,
        showLine: true,
        tension: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const p = results.cml[context.dataIndex];
              const allocationLines = Object.entries(p.weights)
                .filter(([_, weight]) => weight > 0.00005)
                .sort((a, b) => b[1] - a[1])
                .map(([ticker, weight]) => `${ticker}: ${(weight * 100).toFixed(2)}%`);
              const rfAllocation = p.leverage === 0 ? `${((1 - Object.values(p.weights).reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%` : '0%';
              return [
                'Capital Market Line Portfolio',
                ...allocationLines,
                `Risk-Free Asset: ${rfAllocation}`,
                p.leverage > 0 ? `Leverage: ${(p.leverage * 100).toFixed(2)}%` : '',
                '',
                `Expected Return: ${(p.return * 100).toFixed(2)}%`,
                `Risk: ${(p.risk * 100).toFixed(2)}%`,
                `Sharpe Ratio: ${p.sharpe.toFixed(2)}`
              ].filter(line => line);
            }
          }
        }
      }] : [])
    ]
  } : null;

  const formatAssetLabel = (ticker) => {
    if (!results || !results.assets) return ticker;
    const asset = results.assets.find(a => a.ticker === ticker);
    return asset ? `${asset.ticker} (${asset.name})` : ticker;
  };

  const transitionChartData = results && results.frontier && results.frontier.length > 0 ? {
    datasets: Object.keys(results.frontier[0].weights).map((ticker, idx) => {
      const data = results.frontier.map((p) => {
        const previousAssets = Object.keys(results.frontier[0].weights).slice(0, idx);
        const previousSum = previousAssets.reduce((sum, t) => sum + (p.weights[t] || 0) * 100, 0);
        const currentWeight = (p.weights[ticker] || 0) * 100;
        return { x: p.risk * 100, y: previousSum + currentWeight };
      });
      return {
        label: ticker,
        data: data,
        fill: idx === 0 ? 'origin' : `-${idx}`,
        backgroundColor: `rgba(${idx * 50 % 255}, 100, ${(idx + 1) * 50 % 255}, 0.5)`,
        borderColor: `rgba(${idx * 50 % 255}, 100, ${(idx + 1) * 50 % 255}, 1)`,
        borderWidth: 1,
        showLine: true,
        tension: 0.4,
        pointRadius: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const p = results.frontier[context.dataIndex];
              const allocationLines = Object.entries(p.weights)
                .filter(([_, weight]) => weight > 0.00005)
                .sort((a, b) => b[1] - a[1])
                .map(([ticker, weight]) => `${ticker}: ${(weight * 100).toFixed(2)}%`);
              return [
                'Allocation:',
                ...allocationLines,
                '',
                `Expected Return: ${(p.return * 100).toFixed(2)}%`,
                `Standard Deviation: ${(p.std_dev * 100).toFixed(2)}%`,
                uncertaintyFactor > 0 && useFullHistory ? `Uncertainty: ${(p.uncertainty * 100).toFixed(2)}%` : '',
                uncertaintyFactor > 0 && useFullHistory ? `Total Risk: ${(p.risk * 100).toFixed(2)}%` : '',
                `Sharpe Ratio: ${p.sharpe.toFixed(2)}`
              ].filter(line => line);
            }
          }
        }
      };
    })
  } : {
    datasets: [{ label: 'No Data', data: [], showLine: false }]
  };

  return (
    <Loader isLoaded={isLoaded}>
      <div className="efficient-frontier-container">
        <div className="background"></div>
        {/* Title and Overview */}
        <div className="text-box block">
          <div className="title-text">
            <h1 className="efficient-frontier-title">Markowitz Efficient Frontier Calculator</h1>
            <h3 className="efficient-frontier-subsubtitle" onClick={() => setOverviewOpen(!overviewOpen)}>
              Overview <FontAwesomeIcon icon={overviewOpen ? faChevronDown : faChevronRight} />
            </h3>
            {overviewOpen && (
              <div className="efficient-frontier-description">
                <p>
                  Optimize your investment strategy with our Markowitz Efficient Frontier Calculator, a tool designed for portfolio optimization using mean-variance analysis. This calculator helps you achieve the ideal risk-return tradeoff by balancing expected returns with volatility, incorporating uncertainty for a robust approach. Perfect for diversifying across stocks, ETFs, or mutual funds, it leverages historical data and advanced techniques to determine optimal asset allocation.
                </p>
                <p>
                  Unlike other tools, our calculator supports full historical returns for assets with varying lifespans and integrates the Capital Market Line (CML). This feature allows you to explore the best mix of risk-free assets and leverage, maximizing your Sharpe ratio while minimizing risk. Tailor your strategy with a flexible risk-free rate setting to align with your financial goals.
                </p>
                <p>
                  Enhance portfolio diversification and gain data-driven insights for smarter wealth management. Whether you're a seasoned investor or just starting, this efficient frontier tool empowers you to customize your portfolio, ensuring maximum efficiency and informed decision-making for long-term success.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Settings */}
        <div className="text-box block settings">
          <h2 className="efficient-frontier-subtitle">Efficient Frontier Calculator Settings</h2>
          <div className="config-row date">
            <div className="label-wrapper">
              <label>Time Range (Optional) </label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  Set a custom date range for analysis. Leave blank to use all available data.
                </div>
              </span>
            </div>
            <div className="date-inputs">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                placeholderText="Start Date"
                maxDate={endDate || new Date()}
                dateFormat="yyyy-MM-dd"
                className="input-field date-picker"
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                scrollableMonthYearDropdown
              />
              <span> - </span>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                placeholderText="End Date"
                minDate={startDate}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                className="input-field date-picker"
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={10}
                scrollableYearDropdown
                scrollableMonthYearDropdown
              />
            </div>
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Use Full History </label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  When checked, uses all historical data for all assets. Otherwise, restricts history to the age of the shortest asset.
                </div>
              </span>
            </div>
            <input
              type="checkbox"
              checked={useFullHistory}
              onChange={() => setUseFullHistory(!useFullHistory)}
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Uncertainty Factor</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  {useFullHistory
                    ? 'Scales risk for newer assets with less data. Set to 0 to ignore uncertainty.'
                    : 'Disabled unless \'Use Full History\' is checked. Scales risk for newer assets with less data. Set to 0 to ignore uncertainty.'}
                </div>
              </span>
            </div>
            <Slider
              className={`slider ${!useFullHistory ? 'disabled' : ''}`}
              thumbClassName="thumb"
              trackClassName="track"
              min={0}
              max={100}
              step={0.1}
              value={uncertaintyFactor}
              onChange={value => setUncertaintyFactor(value)}
              disabled={!useFullHistory}
            />
            {useFullHistory ? ` ${uncertaintyFactor.toFixed(1)}` : <span className="disabled-text">{uncertaintyFactor.toFixed(1)} </span>}
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Minimum Target Return % (Optional)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  Sets the lowest return for the efficient frontier. Leave blank for the minimum feasible return.
                </div>
              </span>
            </div>
            <input
              type="number"
              value={minTargetReturn}
              onChange={e => setMinTargetReturn(e.target.value)}
              placeholder="Unset"
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Maximum Target Return % (Optional)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  Sets the highest return for the efficient frontier. Leave blank for the maximum feasible return.
                </div>
              </span>
            </div>
            <input
              type="number"
              value={maxTargetReturn}
              onChange={e => setMaxTargetReturn(e.target.value)}
              placeholder="Unset"
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Risk Free Rate (%)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  Used to calculate the Sharpe ratio. Typically the return of a risk-free asset like Treasury bills.
                </div>
              </span>
            </div>
            <input
              type="number"
              value={riskFreeRate}
              onChange={e => setRiskFreeRate(e.target.value)}
              step="0.1"
              className="input-field"
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Show Capital Market Line</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  Displays the Capital Market Line (CML), which shows optimal portfolios combining the risk-free asset and the tangency portfolio, including leveraged positions beyond the efficient frontier.
                </div>
              </span>
            </div>
            <input
              type="checkbox"
              checked={showCML}
              onChange={() => setShowCML(!showCML)}
            />
          </div>
          <div className="config-row">
            <div className="label-wrapper">
              <label>Margin Rate (%)</label>
              <span className="info-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div className="tooltip">
                  The annual cost of borrowing for leveraged positions on the CML. Affects returns beyond the tangency portfolio. Disabled unless CML is shown.
                </div>
              </span>
            </div>
            <input
              type="number"
              value={marginRate}
              onChange={e => setMarginRate(e.target.value)}
              step="0.1"
              className={`input-field ${!showCML ? 'disabled' : ''}`}
              disabled={!showCML}
            />
          </div>
        </div>

        {/* Efficient Frontier Assets */}
        <div className="text-box block">
          <h2 className="efficient-frontier-subtitle">Efficient Frontier Assets</h2>
          <div className="assets-table-container">
            <table className="assets-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Ticker
                    <span onClick={clearAssets} className="clear-btn">
                      <FontAwesomeIcon icon={faTrash} />
                      <div className="tooltip">Clear</div>
                    </span>
                  </th>
                  <th>Min Weight (%)</th>
                  <th>Max Weight (%)</th>
                  <th>Expected Return (%)</th>
                </tr>
              </thead>
              <tbody>
                {assets.map((asset, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td><input value={asset.ticker} onChange={e => handleAssetChange(idx, 'ticker', e.target.value)} className="input-field ticker" /></td>
                    <td><input type="number" placeholder="0" value={asset.minWeight} onChange={e => handleAssetChange(idx, 'minWeight', e.target.value)} className="input-field number" /></td>
                    <td><input type="number max" placeholder="100" value={asset.maxWeight} onChange={e => handleAssetChange(idx, 'maxWeight', e.target.value)} className="input-field number" /></td>
                    <td>
                      <input
                        value={asset.expectedReturn}
                        onChange={e => handleAssetChange(idx, 'expectedReturn', e.target.value)}
                        placeholder="Use Avg."
                        className="input-field expected-return"
                        style={{ fontStyle: asset.expectedReturn ? 'normal' : 'italic' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button onClick={addMoreRows} className="add-btn">Add Rows</button>
          <button onClick={handleSubmit} className="calculate-btn" disabled={loading}>
            {loading ? 'Calculating...' : 'Calculate'}
          </button>
        </div>

        {/* Results Blocks */}
        {loading && (
          <div className="text-box block">
            <DynamicLoader />
          </div>
        )}

        {results && (
          <>
            {/* Optimal Sharpe Ratio Portfolio */}
            <div className="text-box block">
              <h2 className="efficient-frontier-subtitle">Efficient Frontier Calculated ({results.start_date_used} to {results.end_date_used})</h2>
              <h3 className="efficient-frontier-subsubtitle">
                Optimal Sharpe Ratio Portfolio
              </h3>
              <div className="portfolio-container">
                <table className="results-table optimal">
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Allocation (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(results.optimal_portfolio.weights)
                      .filter(([_, weight]) => weight >= 0.00005)
                      .sort(([, weightA], [, weightB]) => weightB - weightA)
                      .map(([ticker, weight]) => (
                        <tr key={ticker}>
                          <td>{formatAssetLabel(ticker)}</td>
                          <td>{(weight * 100).toFixed(2)}%</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="results-table-container">
                <table className="results-table">
                  <thead>
                    <tr>
                      <th>Expected Return (%)</th>
                      <th>Standard Deviation (%)</th>
                      {uncertaintyFactor > 0 && useFullHistory && <th>Uncertainty (%)</th>}
                      {uncertaintyFactor > 0 && useFullHistory && <th>Total Risk (%)</th>}
                      <th>Sharpe Ratio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{(results.optimal_portfolio.return * 100).toFixed(2)}</td>
                      <td>{(results.optimal_portfolio.std_dev * 100).toFixed(2)}</td>
                      {uncertaintyFactor > 0 && useFullHistory && <td>{(results.optimal_portfolio.uncertainty * 100).toFixed(2)}</td>}
                      {uncertaintyFactor > 0 && useFullHistory && <td>{(results.optimal_portfolio.risk * 100).toFixed(2)}</td>}
                      <td>{results.optimal_portfolio.sharpe.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Efficient Frontier Curve with CML */}
            <div className="text-box block">
              <h3 className="efficient-frontier-subsubtitle">Efficient Frontier {showCML ? 'and Capital Market Line' : ''}</h3>
              <div className="chart-container">
                <Scatter
                  data={frontierChartData}
                  options={{
                    scales: {
                      x: {
                        title: { display: true, text: 'Risk (Volatility)', color: 'white' },
                        grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                        ticks: { color: 'white', callback: (value) => `${value.toFixed(2)}%` }
                      },
                      y: {
                        title: { display: true, text: 'Expected Return', color: 'white' },
                        grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                        ticks: { color: 'white', callback: (value) => `${value.toFixed(2)}%` }
                      }
                    },
                    plugins: {
                      legend: { 
                        display: true,
                        labels: {
                          color: 'white',
                          generateLabels: (chart) => {
                            const { datasets } = chart.data;
                            return datasets.map((dataset, i) => {
                              if (dataset.label === 'Capital Market Line') {
                                return {
                                  text: dataset.label,
                                  fillStyle: 'rgba(128, 128, 128, 0.8)',
                                  strokeStyle: 'black',
                                  hidden: !chart.isDatasetVisible(i),
                                  datasetIndex: i,
                                };
                              } else if (dataset.label === 'Efficient Frontier') {
                                return {
                                  text: dataset.label,
                                  fillStyle: 'rgba(100, 255, 0, 1)',
                                  strokeStyle: 'rgba(200, 255, 0, 1)',
                                  hidden: !chart.isDatasetVisible(i),
                                  datasetIndex: i,
                                  fontColor: 'white'
                                };
                              } else if (dataset.label === 'Individual Assets') {
                                return {
                                  text: dataset.label,
                                  fillStyle: 'rgba(220, 0, 255, 1)',
                                  strokeStyle: 'rgba(220, 0, 255, 1)',
                                  hidden: !chart.isDatasetVisible(i),
                                  datasetIndex: i,
                                  fontColor: 'white'
                                };
                              }
                              return {
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor,
                                strokeStyle: dataset.borderColor,
                                hidden: !chart.isDatasetVisible(i),
                                datasetIndex: i,
                                fontColor: 'white'
                              };
                            });
                          }
                        }
                      },
                      tooltip: {
                        enabled: true,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        titleColor: 'white',
                        bodyColor: 'white',
                        borderColor: 'white',
                        borderWidth: 1,
                        mode: 'nearest',
                        intersect: false,
                      }
                    },
                    interaction: {
                      mode: 'nearest',
                      intersect: false,
                      axis: 'xy',
                    },
                    crosshair: {
                      enabled: false
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    responsive: true,
                    maintainAspectRatio: false
                  }}
                />
              </div>
            </div>

            {/* Allocation Transition Map */}
            {/* <div className="text-box block">
              <h3 className="efficient-frontier-subsubtitle">Allocation Transition Map</h3>
              <div className="chart-container">
                <Line
                  data={transitionChartData}
                  options={{
                    scales: {
                      x: {
                        title: { display: true, text: 'Risk (Volatility)', color: 'white' },
                        grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                        ticks: { color: 'white', callback: (value) => `${value.toFixed(2)}%` }
                      },
                      y: {
                        title: { display: true, text: 'Cumulative Allocation', color: 'white' },
                        grid: { display: true, color: 'rgba(255, 255, 255, 0.2)' },
                        ticks: { color: 'white', callback: (value) => `${value.toFixed(2)}%` }
                      }
                    },
                    plugins: {
                      legend: { display: true, labels: { color: 'white' } },
                      tooltip: {
                        enabled: true,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        titleColor: 'white',
                        bodyColor: 'white',
                        borderColor: 'white',
                        borderWidth: 1,
                        mode: 'index'
                      }
                    },
                    interaction: { intersect: false, mode: 'index', axis: 'xy' },
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    responsive: true,
                    maintainAspectRatio: false
                  }}
                />
              </div>
            </div> */}

            {/* Correlation Matrix */}
            <div className="text-box block">
              <h3 className="efficient-frontier-subsubtitle">Correlation Matrix</h3>
              <div className="results-table-container">
                <table className="results-table correlation-matrix">
                <thead>
                  <tr>
                    <th></th>
                    {results.assets.map(a => <th key={a.ticker}>{a.ticker}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {results.correlation_matrix.map((row, i) => (
                    <tr key={i}>
                      <td>{results.assets[i].ticker}</td>
                      {row.map((corr, j) => (
                        <td key={j} style={{ backgroundColor: `rgba(${corr > 0 ? 0 : 230}, ${corr < 0 ? 0 : 230}, 0, ${Math.abs(corr) * 0.95})` }}>
                          {corr.toFixed(2)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>

            {/* Asset List */}
            <div className="text-box block">
              <h3 className="efficient-frontier-subsubtitle">Asset Performance</h3>
              <div className="results-table-container">
                <table className="results-table">
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th>Expected Return (%)</th>
                    <th>Standard Deviation (%)</th>
                    {uncertaintyFactor > 0 && useFullHistory && <th>Uncertainty (%)</th>}
                    {uncertaintyFactor > 0 && useFullHistory && <th>Total Risk (%)</th>}
                    <th>Sharpe Ratio</th>
                  </tr>
                </thead>
                <tbody>
                  {results.assets.map((asset, idx) => (
                    <tr key={idx}>
                      <td>{formatAssetLabel(asset.ticker)}</td>
                      <td>{(asset.return * 100).toFixed(2)}</td>
                      <td>{(asset.std_dev * 100).toFixed(2)}</td>
                      {uncertaintyFactor > 0 && useFullHistory && <td>{(asset.uncertainty * 100).toFixed(2)}</td>}
                      {uncertaintyFactor > 0 && useFullHistory && <td>{(asset.risk * 100).toFixed(2)}</td>}
                      <td>{asset.sharpe.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              
            </div>
          </>
        )}

        {/* Disclosure */}
        <div className="text-box block disclosure">
          <h3 className="efficient-frontier-subsubtitle" onClick={() => setDisclosureOpen(!disclosureOpen)}>
            Disclosure <FontAwesomeIcon icon={disclosureOpen ? faChevronDown : faChevronRight} />
          </h3>
          {disclosureOpen && (
            <p>
              The projections or other information generated by the Efficient Frontier Calculator are hypothetical in nature, do not reflect actual investment results, and are not guarantees of future performance. Results may vary with each use and over time. This tool does not constitute investment advice or recommendation, is provided solely for informational purposes, and is not an offer to buy or sell any securities. All use is subject to terms of service. Investing involves risk, including the possible loss of principal, and past performance is not a guarantee of future results. Asset allocation and diversification strategies do not ensure a profit or protect against a loss. Hypothetical returns do not reflect trading costs, transaction fees, commissions, or actual taxes due on investment returns. The results are based on information from sources we consider reliable, but we do not guarantee their accuracy or completeness. Portfolio optimization is a process of selecting asset proportions to optimize the portfolio based on user-provided inputs, using historical or user-defined data, but optimization results are not guarantees of future performance. The calculator assumes all dividends and distributions are reinvested where applicable. Historical asset statistics are based on data up to the current date and the efficient frontier is derived from the volatilities, correlations, and expected returns of the specified assets. Refer to related documentation for more details on methodology and data sources.
            </p>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default EfficientFrontier;